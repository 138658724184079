а<template>
  <div>
    <b-modal
      id="registration-modal"
      ref="modal"
      title=""
      hide-footer
      hide-header
      no-close-on-backdrop
    >
      <div class="header">
        <span class="close-icon"
              @click="closeModal">
          <img src="@/assets/svg/close.svg"
               aria-label="close">
        </span>

        <h3>Регистрация</h3>

        <div class="subtext">
          Поля, отмеченные*, являются обязательными к заполнению
        </div>
      </div>

      <form ref="form"
            class="modal-form"
            @submit.stop.prevent="submit">
        <div class="inputs-wrapper">
          <div class="position-relative w-100">
            <b-form-group
              id="surname-input"
              label-for="surname-input"
              invalid-feedback="Некорректная фамилия"
            >
              <b-form-input
                id="surname-input"
                placeholder="Фамилия*"
                required
                type="text"
                pattern="[A-Za-z]+"
                v-model="$v.item.surname.$model"
                :state="validateState($v.item.surname)"
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="position-relative w-100">
            <b-form-group
              label-for="name-input"
              invalid-feedback=""
            >
              <b-form-input
                id="name-input"
                placeholder="Имя*"
                v-model="$v.item.name.$model"
                :state="validateState($v.item.name)"
                required
              ></b-form-input>
           </b-form-group>
         </div>
       </div>

       <div class="inputs-wrapper">
        <div class="position-relative w-100">
          <b-form-group
           class="position-relative"
           label-for="email-input"
           invalid-feedback=""
           >
           <b-form-input
             id="email-input"
             placeholder="E-mail*"
             v-model="$v.item.email.$model"
             :state="validateState($v.item.email)"
             required
             type="text"
           ></b-form-input>
         </b-form-group>

         <div class="error"
              v-if="invalidEmail">
           {{ emailError }}
         </div>
        </div>

        <div class="position-relative w-100">
          <b-form-group
            label-for="phone-input"
            invalid-feedback=""
            v-mask="'+7 (###) ###-##-##'"
          >
            <b-form-input
              @click="putPlaceholder"
              id="phone-input"
              placeholder="Номер телефона*"
              v-model="$v.item.phone.$model"
              maxlength="18"
              :state="validateState($v.item.phone)"
              required
            ></b-form-input>
         </b-form-group>

         <div class="error"
              v-if="invalidPhone">
           {{ phoneError }}
         </div>
       </div>
       </div>

       <div class="inputs-wrapper">
        <div class="position-relative w-100">
          <b-form-group
            id="city-input"
            label-for="city-input"
            :invalid-feedback="invalidCity"
          >
            <b-form-input
                id="city-input"
                placeholder="Город"
                required
                @click="list = !list"
                ref="city"
                v-model="$v.item.city.$model"
                class="form-control"
                :class="{ 'opened' : list, 'is-invalid' : isInvalidCity, 'is-valid' : !isInvalidCity }"
                :state="validateState($v.item.city)"
                v-debounce:300ms="searchCities">
            </b-form-input>

            <div class="select-list"
                 v-if="list">
              <div class=""
                   v-for="city in cities"
                   @click="setCity(city)"
                   ref="cities">
                {{ city }}
              </div>

              <div class=""
                   v-if="this.cities.length === 0">
                Введите город
              </div>
            </div>

            <img src="@/assets/svg/carpet-down.svg"
                 class="select-icon"
                 v-if="!list" />

            <img src="@/assets/svg/carpet-down.svg"
                 class="select-icon select-icon__top"
                 v-if="list" />
          </b-form-group>
        </div>

        <div class="position-relative w-100">
          <b-form-group
           class=""
           label-for="card-input"
           invalid-feedback=""
           v-mask="'#### #### #### ####'"
           >
           <b-form-input
             id="card-input"
             placeholder="Номер карты Х5 клуба*"
             v-model="$v.item.card.$model"
             :state="validateState($v.item.card)"
             required
             type="text"
           ></b-form-input>
         </b-form-group>
       </div>
       </div>

       <b-form-checkbox
            id="checkbox-rules"
            class="mt-2 checkbox"
            v-model="$v.item.rules.$model"
            :state="validateState($v.item.rules)"
            name="rules"
            required
            unchecked-value=""
       >
          Я прочитал и принимаю условия
          <a :href="rules[0].url" v-if="rules.length > 0"
             target="_blank">Правил акции*</a>
       </b-form-checkbox>

     <b-form-checkbox
       id="checkbox-policies"
       class="mt-2 rules"
       v-model="$v.item.policies.$model"
       :state="validateState($v.item.policies)"
       name="policies"
       required
       unchecked-value=""
     >
      Я прочитал и принимаю
      <a :href="policies[0].url" v-if="policies.length > 0"
         target="_blank">условия конфиденциальности*</a> <br> и

       <a :href="personalData[0].url" v-if="personalData.length > 0"
          target="_blank">обработки персональных данных*</a>
     </b-form-checkbox>

      <div class="w-100 d-flex justify-content-center captcha mt-2">
          <vue-recaptcha sitekey="6LeNLR4oAAAAAPGxcVanZQb5iBWpWjCCE8Let1Qg"
                         :loadRecaptchaScript="true"
                         class="captcha"
                         size="100%"
                         ref="recaptcha"
                         @verify="onCaptchaVerified"
                         v-model="$v.item.recaptchaResponse.$model"
                         :state="validateState($v.item.recaptchaResponse)"
          ></vue-recaptcha>
        </div>
      </form>

      <div class="button-wrapper">
        <button class="button button-red"
                type="submit"
                onclick="ym(94957508,'reachGoal','registration')"
                :class="{ 'disabled' : disabledBtn }"
                :disabled="disabledBtn"
                @click="submit">
          Зарегистрироваться
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
import VueRecaptcha from 'vue-recaptcha'
import { validationMixin } from "vuelidate"
import { mapGetters, mapMutations } from "vuex"
import { required, minLength, maxLength, or, helpers } from "vuelidate/lib/validators"

const text = helpers.regex('text', /^[?!,.а-яА-ЯёЁ\s]+$/)
const email = helpers.regex('email', /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)
const number = helpers.regex('number', /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/)

  export default {
    components: {
      VueRecaptcha
    },

    mixins: [validationMixin],

    validations: {
      item: {
        name: {
          required,
          minLength: minLength(2),
          text,
        },
        surname: {
          text,
          required
        },
        email: {
          required,
          email,
          minLength: minLength(2),
        },
        phone: {
          required,
          minLength: minLength(18),
          maxLength: maxLength(18),
        },
        city: {
          required
        },
        policies: {
          required
        },
        rules: {
          required
        },
        card: {
          required,
          minLength: minLength(19),
          maxLength: maxLength(19),
        },
        recaptchaResponse: {
        }
      }
    },

    data() {
      return {
        item: {
          name: '',
          city: '',
          surname: '',
          email: '',
          phone: '',
          policies: '',
          rules: '',
          card: '',
          recaptchaResponse: '',
        },
        cities: [],
        list: false,
        recaptcha: null,
        isInvalidCity: false,
        invalidCity: 'Некорректное поле',
        invalidEmail: false,
        invalidPhone: false,
        emailError: 'Адрес почты некорректен или уже принимает участие в Акции',
        phoneError: 'Номер телефона некорректен или уже принимает участие в Акции',
      }
    },

    mounted() {
    },

    computed: {
      ...mapGetters({
        email: 'email',
        policies: 'policies',
        rules: 'rules',
        password: 'password',
        personalData: 'personalData',
      }),

      disabledBtn(){
        if ((this.$v.item.$anyError) || (this.item.name.length < 1) || (this.item.recaptchaResponse.length < 1))
          return true
      }
    },

    methods: {
      ...mapMutations({
        saveToken: 'saveToken',
        getSuccessText: 'getSuccessText',
        getErrorText: 'getErrorText',
        saveEmail: 'saveEmail',
        savePassword: 'savePassword'
      }),

      closeModal(){
        this.$v.$reset()

        this.item.card ='',
        this.item.name = '',
        this.item.email = '',
        this.item.bd = '',
        this.item.surname = '',
        this.item.phone = '',
        this.item.rules = '',
        this.item.city = '',
        this.item.card = '',
        this.item.recaptchaResponse = '',
        this.item.policies = '',
        this.item.password = '',
        this.cities = [],
        this.invalidPhone = false,
        this.invalidEmail = false,

        this.$bvModal.hide('registration-modal')
      },

      searchCities(){
        axios.get('cities?query='+ this.item.city, {
          headers: {
            'Accept': "application/json",
            'Content-Type': "application/json",
            'access-control-allow-origin': "*"
          }
        }).then((response) => {
              this.cities = response.data

              console.log(this.cities)
            }).catch(()=>{
          this.cities = []
        })
      },

      setCity(city) {
        this.item.city = city

        this.list = false
      },

      validateState(item) {
        const { $dirty, $error } = item
        return $dirty ? !$error : null
      },

      putPlaceholder(){
        if (this.item.phone.length < 2){
          this.item.phone = '+7'
        }
      },

      onCaptchaVerified(recaptchaToken) {
        this.item.recaptchaResponse = recaptchaToken
      },

      submit() {
        this.$v.item.$touch()

        this.invalidEmail = false
        this.invalidPhone = false
        this.saveEmail(this.item.email)

        if (this.$v.item.$anyError)
          return false

        axios.post('auth/register', {
          name: this.item.name,
          email: this.item.email,
          phone: this.item.phone,
          city: this.item.city,
          card_number: this.item.card,
          surname: this.item.surname,
          accept_policies: this.item.policies,
          accept_rules: this.item.rules,
          "g-recaptcha-response": this.item.recaptchaResponse
        }).then((response) => {
          this.closeModal()
          this.$bvModal.show('password-sent-modal')
        }).catch((error) => {
          if ((error.response.data.errors.phone)){
            this.invalidPhone = true
          }

          if ((error.response.data.errors.email)){
            this.invalidEmail = true
          }
        })
      },
    }
  }
</script>

<style scoped lang="scss">
.inputs-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media(max-width: 768px){
    flex-direction: column;
  }

  .position-relative {

    .select-list {
      div {
        width: 100% !important;
      }
    }
  }
}

.form-control {
  font-weight: 400;
  font-size: 15px !important;
}

.subtext {
  color: $white;
  font-family: $regular;
  font-weight: 400;
  text-align: center;
  margin-bottom: 25px;
  font-size: 12px;
  margin-top: -5px;
}

.button-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
}

.button {
  margin-top: 10px;
  width: 100%;
  max-width: 255px;

  &.disabled {
    pointer-events: none;
  }

  &:hover {
    opacity: 0.8 !important;
  }
}

.checkbox {
  margin-bottom: 10px;
  color: $white;
}

.form-group {
  width: 97%;
}

.error {
  font-size: 8px;
  color: red;
  margin-top: -15px;
  position: absolute;
}

.width-spec {
  max-width: 96.5%;
}

.captcha {
  transform: none;
  justify-content: center;
  margin-bottom: 10px;
}

.select-icon {
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 15px;
  margin-top: 12px;
}

.select-list {
  position: absolute;
  margin-top: -10px;
  width: 100%;
  z-index: 99999;
  border-top: none;
  border-radius: 0 0 25px 25px;
  background-color: $white;
  border: 1px solid $green;
  border-top: none;
  max-height: 430px;
  overflow-y: scroll;

  div {
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 25px;
    background-color: $white;
    border-radius: 0 0 25px 25px;
    cursor: pointer;
    transition: .5s all;
    font-family: $regular;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    color: $black;

    &.active {
      background-color: $green;
      color: $red;
    }

    &:hover {
      color: $green;
    }
  }
}

.opened {
  transition: 0s all !important;
  border: 1px solid #cecece;
  border-bottom: none !important;
  border-radius: 25px 25px 0 0 !important;
}

#city-input {
  transition: 0s all !important;
}
</style>
