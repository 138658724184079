<template>
  <section class="promo-wrapper">
    <div>
      <img src="@/assets/visual.png" class="visual desktop" />

      <img src="@/assets/visual@mobile.png" class="visual mobile" />
    </div>

    <div class="text-wrapper">
      <img src="@/assets/visual-text.png" class="text" />
    </div>

    <router-link :to="{ path: '/#participate' }"
                 v-scroll-to="'#participate'"
                 class="header-link scroll">
      <img src="@/assets/arrow_down.gif"
           class="scroll" />
    </router-link>

    <div class="right-text">
      с призами <br>за покупки <br> в «Пятёрочке»!
    </div>

    <img src="@/assets/visual-right.png"
         class="right-img mobile" />

    <img src="@/assets/svg/visual-desktop.svg"
         class="right-img desktop" />

    <div class="text-mobile mobile">
      с призами за покупки <br> в «Пятёрочке»!
    </div>
  </section>
</template>

<script>

export default {
  name: 'Promo',

  data: () => ({
  }),

  computed: {
  },
}
</script>

<style scoped lang="scss">
.promo-wrapper {
  padding-top: 150px;
  padding-bottom: 60px;
  background-color: $green;
  background-image: url('@/assets/promo-bg.png');
  font-family: $regular;
  padding-left: 0;
  color: $white;
  display: flex;
  position: relative;
  background-size: cover;
  height: calc(100vh - 80px);
  align-items: flex-start;
  justify-content: space-between;
  background-repeat: no-repeat;

  @media(max-width: 876px){
    background-position: left top;
    height: calc(100vh - 222px);
  }
}

.right-img {
  position: absolute;
  right: 0;
  z-index: 2;
  bottom: 0;

  @media(max-width: 1200px){
    max-width: 320px;
  }

  @media(max-width: 876px){
    max-width: 400px;
    margin-bottom: 74px;
  }

  @media(max-width: 576px){
    max-width: 300px;
  }

  @media(max-width: 460px){
    max-width: 180px;
  }
}

.right-text {
  background-color: $red;
  position: absolute;
  right: 0;
  padding-left: 22%;
  width: 80%;
  bottom: 0;
  margin-bottom: 100px;
  display: flex;
  align-items: center;
  height: 204px;
  color: $white;
  z-index: 1;
  font-family: $regular;
  font-size: 50px;
  font-style: normal;
  font-weight: 500;
  line-height: 110%;

  @media(max-width: 1600px){
    padding-left: 19%;
  }

  @media(max-width: 1200px){
    font-size: 30px;
    height: 180px;
    padding-left: 25%;
  }

  @media(max-width: 876px){
    display: none;
  }
}

.visual {
  z-index: 10;
  position: absolute;
  bottom: 0;
  max-width: 740px;
  margin-top: -65px;
  width: 100%;

  @media(max-width: 1600px){
    max-width: 590px;
  }

  @media(max-width: 1200px){
    max-width: 500px;
  }

  @media(max-width: 1600px) and (max-height: 750px){
    max-width: 550px;
  }

  @media(max-width: 876px){
    max-width: 540px;
    z-index: 1;
    margin-bottom: 74px;
  }

  @media(max-width: 576px){
    max-width: 380px;
  }

  @media(max-width: 460px){
    max-width: 280px;
  }
}

.text-wrapper {
  position: absolute;
  right: 0;
  padding-left: 22%;
  width: 80%;
  margin-top: 100px;

  @media(max-width: 1600px){
    margin-top: 0px;
    padding-left: 19%;
  }

  @media(max-width: 1600px) and (max-height: 750px){
    margin-top: -80px;
  }

  @media(max-width: 1200px){
    padding-left: 25%;
    margin-top: 30px;
  }

  @media(max-width: 876px){
    right: 0;
    top: 0;
    margin-top: 50px;
    padding-left: 0;
    width: auto;
    margin-right: 20px;
  }

  @media(max-width: 576px){
    margin-top: 30px;
  }

  .text {
    max-width: 460px;

    @media(max-width: 1600px){
      max-width: 365px;
    }

    @media(max-width: 1600px) and (max-height: 750px){
      max-width: 310px;
    }

    @media(max-width: 1200px){
      max-width: 270px;
    }

    @media(max-width: 876px){
      max-width: 230px;
    }

    @media(max-width: 576px){
      max-width: 170px;
    }

    @media(max-width: 460px){
      max-width: 100px;
    }
  }
}


.scroll {
  width: 56px;
  height: 56px;
  position: absolute;
  bottom: 0;
  margin-bottom: 10px;
  transform: translateX();
  right: 50%;
  left: 50%;

  @media(max-width: 876px){
    display: none;
  }
}


.desktop {
  @media(max-width: 876px){
    display: none;
  }
}

.mobile {
  display: none !important;

  @media(max-width: 876px){
    display: block !important;
  }
}

.text-mobile {
  background-color: $red;
  position: absolute;
  bottom: 0;
  height: 74px;
  align-items: center;
  justify-content: center;
  color: #FFF;
  text-align: center;
  font-family: $regular;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 110%; /* 26.4px */
  width: 100%;
  z-index: 15;

  @media(max-width: 876px){
    display: flex !important;
  }
}
</style>
