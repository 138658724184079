<template>
  <b-modal
    id="voucher-modal"
    ref="modal"
    title=""
    hide-footer
    hide-header
    centered
    no-close-on-backdrop
  >
    <div class="header">
      <span class="close-icon"
            @click="closeModal">
        <img src="@/assets/svg/close.svg"
             aria-label="close">
      </span>

      <h3>
         Регистрация чека
      </h3>

      <div>
        <div class="step">
          <p style="margin-right: 5px;">1. </p>

          <p>
            Нажмите на область «Перетащите фото чека» или нажмите для выбора
            и выберите фото чека.
          </p>
        </div>

        <div class="step">
          <p style="margin-right: 5px;">2. </p>

          <p>
            Если чек длинный, сделайте несколько последовательных фото.
          </p>
        </div>

        <div class="step">
          <p style="margin-right: 5px;">3. </p>

          <p>
            Если на чеке есть QR-код — сделайте фото отдельным кадром.
          </p>
        </div>

        <div class="step">
          <p style="margin-right: 5px;">4. </p>

          <p>
            Если фото окажется плохого качества — чек принят не будет.
          </p>
        </div>
      </div>
    </div>

    <div  ref="form"
          class="form modal-form"
          @submit.stop.prevent="submit">
        <span class="custom-dropdown-zone">
          <span class="dropzone-text"
                v-if="(files.length === 0)">
            Перетащите фото чека сюда <br>
            или выберите файл
          </span>

          <span class="img-w"
                v-for="(image, index) in files"
                :key="index">

             <div @click.prevent="deleteImage(index)"
                  class="delete-img"
              >
                <img src="@/assets/svg/close.svg"
                     alt="close" />
              </div>

             <img class="image"
                  :src="image.src"
                  alt="image">
          </span>

          <vue-dropzone @vdropzone-complete="afterComplete"
                        id="dropImg"
                        :options="dropOptions">
          </vue-dropzone>
        </span>


      <div class="form-inputs"
           v-if="showInputs">
        <h5>Проверьте или введите данные по чеку вручную:</h5>

        <b-form-group
          id="fp-input"
          label-for="fp-input"
          invalid-feedback="Некорректное поле"
        >
          <b-form-input
            id="fp-input"
            placeholder="ФП"
            required
            type="text"
            v-model="$v.item.fp.$model"
            :state="validateState($v.item.fp)"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="fn-input"
          label-for="fn-input"
          invalid-feedback="Некорректное поле"
        >
          <b-form-input
            id="fn-input"
            placeholder="ФН"
            required
            type="text"
            v-model="$v.item.fn.$model"
            :state="validateState($v.item.fn)"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="fd-input"
          label-for="fd-input"
          invalid-feedback="Некорректное поле"
        >
          <b-form-input
            id="fd-input"
            placeholder="ФД"
            required
            type="text"
            v-model="$v.item.fd.$model"
            :state="validateState($v.item.fd)"
          ></b-form-input>
        </b-form-group>


        <b-form-group
          id="date-input"
          label-for="date-input"
          invalid-feedback="Некорректная дата"
          v-mask="'##.##.####'"
        >
          <b-form-input
            id="date-input"
            placeholder="Дата покупки"
            required
            type="text"
            v-model="$v.item.date.$model"
            :state="validateState($v.item.date)"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="time-input"
          label-for="time-input"
          invalid-feedback="Некорректное время"
          v-mask="'##:##'"
        >
          <b-form-input
            id="time-input"
            placeholder="Время покупки"
            required
            type="text"
            v-model="$v.item.time.$model"
            :state="validateState($v.item.time)"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="total-input"
          label-for="total-input"
          invalid-feedback="Некорректное поле"
        >
          <b-form-input
            id="total-input"
            placeholder="Сумма чека"
            required
            type="text"
            v-model="$v.item.total.$model"
            :state="validateState($v.item.total)"
          ></b-form-input>
        </b-form-group>

        <b-button class="button button-red"
                  type="submit"
                  :disabled="disabledBtn"
                  onclick="ym(94957508,'reachGoal','send_check')"
                  :class="{ disabled: disabledBtn || isLoading }"
                  @click="submit">Отправить чек</b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import axios from 'axios'
import QrScanner from 'qr-scanner'
import { validationMixin } from "vuelidate"
import vueDropzone from "vue2-dropzone"
import { mapGetters, mapMutations } from "vuex"
import { required, requiredIf, minLength, maxLength, or, helpers } from "vuelidate/lib/validators"

const number = helpers.regex('number', /^[:]*[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/)

export default {
  name: "CashVoucher",

  components: {
    vueDropzone
  },

  mixins: [validationMixin],

  validations: {
    item: {
      fp: {
        required,
        number,
      },
      fn: {
        required,
        number,
      },
      fd: {
        required,
        number,
      },
      date: {
        required,
        minLength: minLength(10),
        maxLenght: maxLength(10),
        number,
      },
      time: {
        required,
        minLength: minLength(5),
        maxLenght: maxLength(5),
      },
      total: {
        required,
        number,
      },
    }
  },

  data(){
    return{
      item: {
        fp: '',
        fn: '',
        fd: '',
        date: '',
        time: '',
        total: ''
      },
      dropOptions: {
        url: "https://httpbin.org/post",
        acceptedFiles:'image/png, image/jpg, image/jpeg, image/bmp',
        maxFilesize: 5, // MB
      },
      isLoading: false,
      result: '',
      files: [],
      qrData: [],
      showInputs: false,
      t:'',
    }
  },

  computed: {
    ...mapGetters({
      token: 'token',
    }),

    disabledBtn(){
      if (this.$v.item.$anyError)
        return true
    },
  },

  methods: {
    ...mapMutations({
      saveUser: 'saveUser',
      saveToken: 'saveToken',
      getSuccessText: 'getSuccessText',
      getErrorText: 'getErrorText'
    }),

    afterComplete(file){
      QrScanner.scanImage(file)
          .then(result => {
            let newDate;
            this.qrData = result.split('&')
            newDate = this.qrData.map(value =>{
              return value.split('=')
            })
            newDate.forEach(value=>{
              if(value[0] === 'fn'){
                this.item.fn = value[1]
              }
              if(value[0] === 'fp'){
                this.item.fp = value[1]
              }
              if(value[0] === 'i'){
                this.item.fd = value[1]
              }
              if(value[0] === 's'){
                this.item.total = value[1]
              }
              if(value[0] === 't'){
                this.t = value[1]
                let chDate = value[1].split('T')[0].split('')
                let chTime = value[1].split('T')[1].split('')

                this.item.date = chDate[6]+chDate[7]+'.'+chDate[4]+chDate[5]+'.'+chDate[0]+chDate[1]+chDate[2]+chDate[3],
                this.item.time = chTime[0]+chTime[1]+':'+chTime[2]+chTime[3]
              }
            })
            let reader = new FileReader();
            reader.onload = (e) => {
              this.files.push({
                src: e.target.result,
                correct: true,
                data: file
              })
            };
            reader.readAsDataURL(file)
          })
          .catch((error) => {
            console.log(error)
            let reader = new FileReader()
            reader.onload = (e) => {
              this.files.push({
                src: e.target.result,
                correct: false,
                data: file
              })
            }
            reader.readAsDataURL(file)
          })
      this.showInputs = true
    },

    closeModal(){
      this.$bvModal.hide('voucher-modal')

      this.files = [],
      this.showInputs = false,
      this.item.fp = '',
      this.item.fd = '',
      this.item.fn = '',
      this.item.date = '',
      this.item.time = '',
      this.item.total = ''
    },

    resetModal(){
      this.$bvModal.hide('voucher-modal')

      this.$emit('getThanksText', "Ваш чек отправлен на модерацию")
      this.$bvModal.show('thanks-modal')

      this.files = [],
      this.showInputs = false,
      this.item.fp = '',
      this.item.fd = '',
      this.item.fn = '',
      this.item.date = '',
      this.item.time = '',
      this.item.total = ''

      this.$store.dispatch('getReceipts')
    },

    clear(){
      this.item.fp = '',
      this.item.fd = '',
      this.item.fn = '',
      this.item.date = '',
      this.item.time = '',
      this.item.total = ''
    },

    logout(){
      axios.post('auth/logout', {
      }, {
        headers: {
          'Accept': "application/json",
          'Content-Type': "application/json",
          'access-control-allow-origin': "*",
          'Authorization': "Bearer " + localStorage.getItem('token')
        }
      }).then((response) => {
        this.saveUser(null)
        this.saveToken(null)
        this.$router.push('/')

        localStorage.removeItem('token')
      }).catch((error) => {
          console.log(error)
      })
    },

    validateState(item) {
      const { $dirty, $error } = item
      return $dirty ? !$error : null
    },

    deleteImage(index){
      this.files.splice(index, 1)
      if(!this.files.filter(value => value.correct).length){
        this.clear()
      }
      if(this.files.length === 0){
        this.clear()
        this.showInputs = false
      }
    },

    onDecode (event) {
      QrScanner.scanImage(event.target.files[0])
          .then(result => {
            let newDate

            this.qrData = result.split('&')

            newDate = this.qrData.map(value =>{
              return value.split('=')
            })
            newDate.forEach(value=>{
              if(value[0] === 'fn'){
                this.item.fn = value[1]
              }
              if(value[0] === 'fp'){
                this.item.fp = value[1]
              }
              if(value[0] === 'i'){
                this.item.fd = value[1]
              }
              if(value[0] === 's'){
                this.item.total = value[1]
              }
              if(value[0] === 't'){
                this.t = value[1]
                let chDate = value[1].split('T')[0].split('')
                let chTime = value[1].split('T')[1].split('')

                this.item.date = chDate[6]+chDate[7]+'.'+chDate[4]+chDate[5]+'.'+chDate[0]+chDate[1]+chDate[2]+chDate[3],
                this.item.time = chTime[0]+chTime[1]+':'+chTime[2]+chTime[3]
              }
            })
            let reader = new FileReader();
            reader.onload = (e) => {
              this.files.push({
                src: e.target.result,
                correct: true,
                data: event.target.files[0]
              })
            };
            reader.readAsDataURL(event.target.files[0])
          })
          .catch((error) => {
            console.log(error)
            let reader = new FileReader();
            reader.onload = (e) => {
              this.files.push({
                src: e.target.result,
                correct: false,
                data: event.target.files[0]
              })
            }
            reader.readAsDataURL(event.target.files[0])
          })
      this.showInputs = true
    },

    submit() {
      this.$v.item.$touch()

      if (this.$v.item.$anyError)
        return false

      const formData = new FormData()
      this.files.forEach((value, index)=>{
        formData.append('file', value.data)
      })
      formData.append('fp', this.item.fp)
      formData.append('fn', this.item.fn)
      formData.append('fd', this.item.fd)
      formData.append('datetime', this.item.date + ' ' + this.item.time + ':00')
      formData.append('price', this.item.total)
      axios.post('receipts', formData, {
      }, {
        headers: {
          'Accept': "application/json",
          'Content-Type': "application/json",
          'access-control-allow-origin': "*",
          'Authorization': "Bearer " + localStorage.getItem('token')
        }
      }).then((response) => {
        this.resetModal()
      }).catch((error) => {
        if (error.response.data.message === "Время жизни сессии истекло. Пожалуйста, авторизуйтесь"){
          this.getErrorText(error.response.data.message)

          this.logout()
          this.closeModal()
          this.$bvModal.show('error-modal')
        }

        if (error.response.data.message === "С момента прошлого запроса не прошло 3 минуты"){
          this.getErrorText(error.response.data.message)

          this.closeModal()
          this.$bvModal.show('error-modal')
        }

        if (error.response.data.message === "Ты уже загрузил максимальное количество чеков за весь период проведения акции."){
          this.getErrorText(error.response.data.message)

          this.closeModal()
          this.$bvModal.show('error-modal')
        }

        if (error.response.data.message && error.response.data.errors){
          this.getErrorText("Размер файла в поле не может превышать 5мб")
          this.$bvModal.show('error-modal')
        }

        if (error.response.data.message && error.response.data.errors.datetime){
          this.getErrorText("Поле дата покупки не соответствует формату")
          this.$bvModal.show('error-modal')
        }

        if (error.response.data.message && error.response.data.errors.price){
          this.getErrorText("Поле сумма покупки не соответствует формату")
          this.$bvModal.show('error-modal')
        }

        if (error.response.data.message && error.response.data.errors.fd){
          this.getErrorText("Поле ФД не соответствует формату")
          this.$bvModal.show('error-modal')
        }

        if (error.response.data.message && error.response.data.errors.fn){
          this.getErrorText("Поле ФН не соответствует формату")
          this.$bvModal.show('error-modal')
        }

        if (error.response.data.message && error.response.data.errors.fp){
          this.getErrorText("Поле ФП не соответствует формату")
          this.$bvModal.show('error-modal')
        }
      })
    },
  }
}
</script>
<style scoped lang="scss">
.step {
  display: flex;

  p {
    font-size: 15px;
    font-weight: 400;
    line-height: 120%;
    color: $white;
    font-family: $regular;
    margin-bottom: 3px;
  }
}

.button-red-outlined {
  margin-bottom: 20px;
  border: 2px solid $red !important;
  background-color: transparent !important;
  width: 100%;
  color: $red !important;

  &:hover {
    background-color: rgba(228, 0, 43, 0.15) !important;
  }
}

input[type='file'] {
  position: absolute;
  opacity: 0;
  height: 0;
}

.modal-form {
  margin-top: 15px;
}

.custom-dropdown-zone {
  width: 100%;
  background: #fff;
  min-height: 150px;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  border-radius: 25px;
  padding: 16px 8px;
  margin-bottom: 30px;
  box-shadow: 0px 7px 20px 2px rgba(103, 103, 103, 0.1) !important;

  .dropzone-text {
    font-family: $regular;
    color: black;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 12px */

    @media(max-width: 576px){
      font-size: 12px;
    }
  }

  .vue-dropzone {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .img-w {
    height: 100px;
    width: auto;
    min-width: 100px;
    margin: 5px;
    position: relative;
    z-index: 10;

  .delete-img {
      position: absolute;
      top: -10px;
      right: -10px;
      background-color: $red;
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      opacity: 0.55;
      border-radius: 50%;
      transition: .4s all;

      &:hover {
        opacity: 1;
      }

      img {
        max-width: 20px;
      }
    }
    .image {
      height: 100%;
      width: auto;
    }
  }
}

.form-inputs {
  margin-top: 20px;
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

::v-deep .dz-preview {
  display: none;
}

::v-deep .dz-default {
  display: none;
}

.button {
  width: 100% !important;
  border-radius: 40px;
  width: fit-content !important;
}

.fake-button-red {
  background-color: $red;
  width: 100%;
  cursor: pointer;
  transition: .5s all;
  height: 50px;
  color: $white;
  font-family: $regular;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;

  &:hover {
    opacity: 0.9;
  }
}

.modal-form,
.form {
  padding: 0 !important;
}

label {
  width: 100%;
}

h5 {
  color: $white;
  font-family: $regular;
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: 100%;
  margin-bottom: 20px;
}

.form-group {
  width: 100%;
  margin-bottom: 15px;
}
</style>
