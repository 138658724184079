<template>
  <div>
    <b-modal
      id="email-modal"
      ref="modal"
      title=""
      hide-footer
      hide-header
      centered
    >
      <div class="header">
        <span class="close-icon"
              @click="$bvModal.hide('email-modal')">
          <img src="@/assets/svg/close.svg"
               aria-label="close">
        </span>

        <h3>
          На указанный E-mail был <br> отправлен пароль
        </h3>

        <div class="subtext">Пароль придет на указанный E-mail в течение десяти минут</div>
      </div>

      <div class="w-100 content-modal">
        <form ref="form"
              class="form w-100 modal-form"
              @submit.stop.prevent="submit">
         <b-form-group
           label-for="password-input"
           class="position-relative"
           :invalid-feedback="invalidPassword"
         >
           <b-form-input
             id="password-input"
             placeholder="Пароль"
             v-model="$v.item.password.$model"
             :state="validateState($v.item.password)"
             required
             :class="{ 'is-invalid' : isInvalidPassword }"
             type="password"
           ></b-form-input>
         </b-form-group>
        </form>

        <button class="button button-green"
                 type="submit"
                 :class="{ 'disabled' : disabledBtn }"
                 @click="submit">
          Отправить
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
import { validationMixin } from "vuelidate"
import { mapGetters, mapMutations } from "vuex"
import { required, requiredIf, minLength, maxLength, or, helpers } from "vuelidate/lib/validators"

export default {
  mixins: [validationMixin],

  validations: {
    item: {
      password: {
        required,
      },
    }
  },

  data() {
    return {
      item: {
        password: ''
      },
      isInvalidPassword: false,
      invalidPassword: 'Некорректное поле',
    }
  },

  computed: {
    ...mapGetters({
      email: 'email',
    }),

    disabledBtn(){
      if ((this.$v.item.$anyError) || (this.item.password.length < 1))
        return true
    }
  },

  methods: {
    ...mapMutations({
      saveToken: 'saveToken',
      getSuccessText: 'getSuccessText',
      getErrorText: 'getErrorText'
    }),

    closeModal() {
      this.$v.$reset()
      this.$bvModal.hide('email-modal')

      this.item.password = ''
    },

    validateState(item) {
      const { $dirty, $error } = item
      return $dirty ? !$error : null
    },

    submit() {
      this.$v.item.$touch()

      if (this.$v.item.$anyError)
        return false

      axios.post('auth/login', {
        email: this.email,
        password: this.item.password
      }, {
        headers: {
          'Accept': "application/json",
          'Content-Type': "application/json",
          'access-control-allow-origin': "*",
          'Authorization': ''
        }
      }).then((response) => {
        this.closeModal()
        this.$router.push('/office')

        axios.defaults.headers.authorization = "Bearer " + response.data.response.token

        this.saveToken(response.data.response.token)
        sessionStorage.setItem('token', response.data.response.token)
        localStorage.setItem('token', response.data.response.token)

        setTimeout(() => {
          this.$store.dispatch('getUser')
        })
      }).catch((error) => {
        this.closeModal()

        this.getErrorText(error.response.data.response.message)
        this.$bvModal.show('error-modal')
      })
    },
  }
}
</script>

<style scoped lang="scss">
.header {
  flex-direction: column;
  width: 100%;

  .close-icon {
    float: right;
    cursor: pointer;
    transition: .4s all;
    margin-right: 5px;

    &:hover {
      opacity: .85;
    }
  }

  h5 {
    width: 100%;
    color: $darker-grey;
    margin-top: 70px !important;
    margin-bottom: 30px;
    font-weight: 800;
    font-family: $regular;
    font-size: 22px;
    text-transform: uppercase;
    text-align: center;
    padding-bottom: 30px;
  }
}

h3 {
  margin-bottom: 10px !important;
}

.subtext {
  font-size: 16px;
  margin-bottom: 40px;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
}
</style>
