<template>
  <section class="promo-wrapper" id="products">
    <h1 class="section-title">Продукты</h1>

    <div class="content">
      <div class="product">
        <img src="@/assets/products/img-1.png" />

        <div class="title">
          АктиБио Биойогурт

        </div>

        <div class="number">
          220 г
        </div>
      </div>

      <div class="product">
        <img src="@/assets/products/img-2.png" />

        <div class="title">
          АктиБио Биойогурт <br> Вишня
        </div>

        <div class="number">
          220 г
        </div>
      </div>

      <div class="product">
        <img src="@/assets/products/img-3.png" />

        <div class="title">
          АктиБио Биойогурт <br> Отруби и Злаки
        </div>

        <div class="number">
          220 г
        </div>
      </div>

      <div class="product">
        <img src="@/assets/products/img-4.png" />

        <div class="title">
          АктиБио Биойогурт
        </div>

        <div class="number">
          130 г
        </div>
      </div>

      <div class="product">
        <img src="@/assets/products/img-5.png" />

        <div class="title">
          АктиБио Биойогурт <br> Отруби и Злаки
        </div>

        <div class="number">
          130 г
        </div>
      </div>

      <div class="product">
        <img src="@/assets/products/img-6.png" />

        <div class="title">
          АктиБио Биойогурт <br> Лён Черника и 5 злаков

        </div>

        <div class="number">
          130 г
        </div>
      </div>

      <div class="product">
        <img src="@/assets/products/img-7.png" />

        <div class="title">
          АктиБио Биойогурт <br> Вишня
        </div>

        <div class="number">
          130 г
        </div>
      </div>

      <div class="product">
        <img src="@/assets/products/img-8.png" />

        <div class="title">
          АктиБио Биойогурт <br> Клубника

        </div>

        <div class="number">
          130 г
        </div>
      </div>

      <!-- All products start here -->

      <div class="product" v-if="products">
        <img src="@/assets/products/img-9.png" />

        <div class="title">
          АктиБио Биойогурт <br> Чернослив

        </div>

        <div class="number">
          130 г
        </div>
      </div>

      <div class="product" v-if="products">
        <img src="@/assets/products/img-10.png" class="img-spec" />

        <div class="title">
          АктиБио Биойогурт <br> Термостатный

        </div>

        <div class="number">
          160 г
        </div>
      </div>

      <div class="product" v-if="products">
        <img src="@/assets/products/img-11.png" class="img-spec" />

        <div class="title">
          АктиБио Биойогурт <br> Термостатный Клубника

        </div>

        <div class="number">
          160 г
        </div>
      </div>

      <div class="product" v-if="products">
        <img src="@/assets/products/img-12.png" class="img-spec" />

        <div class="title">
          АктиБио Биойогурт <br> Термостатный Персик

        </div>

        <div class="number">
          160 г
        </div>
      </div>

      <div class="product" v-if="products">
        <img src="@/assets/products/img-13.png" />

        <div class="title">
          АктиБио Биойогурт

        </div>

        <div class="number">
          260 г
        </div>
      </div>

      <div class="product" v-if="products">
        <img src="@/assets/products/img-14.png" />

        <div class="title">
          АктиБио Биойогурт <br> Клубника Земляника
        </div>

        <div class="number">
          260 г
        </div>
      </div>

      <div class="product" v-if="products">
        <img src="@/assets/products/img-15.png" />

        <div class="title">
          АктиБио Биойогурт <br> Чернослив

        </div>

        <div class="number">
          260 г
        </div>
      </div>

      <div class="product" v-if="products">
        <img src="@/assets/products/img-16.png" />

        <div class="title">
          АктиБио Биойогурт <br> Дыня Клубника Земляника
        </div>

        <div class="number">
          260 г
        </div>
      </div>

      <div class="product" v-if="products">
        <img src="@/assets/products/img-17.png" />

        <div class="title">
          АктиБио Биойогурт <br> Злаки

        </div>

        <div class="number">
          260 г
        </div>
      </div>

      <div class="product" v-if="products">
        <img src="@/assets/products/img-18.png" />

        <div class="title">
          АктиБио Биойогурт <br> Черника Злаки Лён
        </div>

        <div class="number">
          260 г
        </div>
      </div>

      <div class="product" v-if="products">
        <img src="@/assets/products/img-19.png" />

        <div class="title">
          АктиБио Биойогурт <br> Клубника Черника

        </div>

        <div class="number">
          260 г
        </div>
      </div>

      <div class="product" v-if="products">
        <img src="@/assets/products/img-20.png" />

        <div class="title">
          АктиБио Биойогурт <br> Персик Яблоко

        </div>

        <div class="number">
          260 г
        </div>
      </div>

      <div class="product" v-if="products">
        <img src="@/assets/products/img-21.png" />

        <div class="title">
          АктиБио Биойогурт <br> Чернослив

        </div>

        <div class="number">
          870 г
        </div>
      </div>

      <div class="product" v-if="products">
        <img src="@/assets/products/img-22.png" />

        <div class="title">
          АктиБио Биойогурт <br> Клубника Земляника

        </div>

        <div class="number">
          870 г
        </div>
      </div>

      <div class="product" v-if="products">
        <img src="@/assets/products/img-23.png" />

        <div class="title">
          АктиБио Биойогурт <br> Чернослив

        </div>

        <div class="number">
          870 г
        </div>
      </div>

      <div class="product" v-if="products">
        <img src="@/assets/products/img-24.png" />

        <div class="title">
          АктиБио Биойогурт <br> Злаки

        </div>

        <div class="number">
          870 г
        </div>
      </div>

      <div class="product" v-if="products">
        <img src="@/assets/products/img-25.png" />

        <div class="title">
          АктиБио Биойогурт <br> Вишня Яблоко Малина
        </div>

        <div class="number">
          130 г
        </div>
      </div>

      <div class="product" v-if="products">
        <img src="@/assets/products/img-26.png" />

        <div class="title">
          АктиБио Биойогурт <br> Малина

        </div>

        <div class="number">
          110 г
        </div>
      </div>

      <div class="product" v-if="products">
        <img src="@/assets/products/img-27.png" />

        <div class="title">
          АктиБио Биойогурт <br> Манго

        </div>

        <div class="number">
          110 г
        </div>
      </div>

      <div class="product" v-if="products">
        <img src="@/assets/products/img-28.png" />

        <div class="title">
          АктиБио Биойогурт <br> Черника и Ежевика

        </div>

        <div class="number">
          110 г
        </div>
      </div>

      <div class="w-100 d-flex justify-content-center mt-5">
        <button class="button button-red" @click="products = !products" v-if="!products">
          Показать всю продукцию
        </button>

        <button class="button button-red" @click="products = !products" v-if="products">
          Скрыть всю продукцию
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapMutations } from "vuex"

export default {
  name: 'Products',

  data: () => ({
    products: false
  }),

  computed: {
  },

  methods: {
  }
}
</script>

<style scoped lang="scss">
.promo-wrapper {
  background-image: url('@/assets/svg/pattern@big.svg');
  background-size: cover;
  font-family: $regular;
  background-color: $white;
  background-repeat: no-repeat;
  padding-top: 90px;
  background-position: top center;
  padding-bottom: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media(max-width: 768px){
    padding: 50px 20px;
    background-image: url('@/assets/svg/pattern@mobile.svg');
  }

  .section-title {
    color: #392A25;
    font-size: 48px;
  }

  .content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 10px;
  }
}

.product {
  width: 100%;
  max-width: 290px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;

  @media(max-width: 1600px){
    max-width: 260px;
  }

  @media(max-width: 576px){
    max-width: 150px;
  }

  .img-spec {
    max-width: 250px;

    @media(max-width: 576px){
      max-width: 150px;
    }
  }

  img {
    max-width: 180px;
    margin-bottom: 40px;

    @media(max-width: 576px){
      max-width: 115px;
      margin-bottom: 20px;
    }
  }

  .title {
    text-align: center;
    height: 45px;
    color: #392A25;
    text-align: center;
    font-family: $regular;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    @media(max-width: 576px){
      font-size: 11px;
      height: 28px;
    }
  }

  .number {
    text-align: center;
    color: #02BA44;
    text-align: center;
    font-family: $regular;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    @media(max-width: 576px){
      font-size: 11px;
    }
  }
}

.disabled {
  pointer-events: none;
  filter: grayscale(100%);
}
</style>
