<template>
  <div>
    <div class="content-modal">
      <div class="subtext">
        Отправьте нам сообщение <br> и мы обязательно ответим!
      </div>

      <form ref="form"
            class="modal-form"
            @submit.stop.prevent="submit">
        <b-form-group
          id="name-input"
          label-for="name-input"
          invalid-feedback="Некорректное имя"
        >
          <b-form-input
            id="name-input"
            placeholder="Имя"
            required
            type="text"
            pattern="[A-Za-z]+"
            v-model="$v.item.name.$model"
            :state="validateState($v.item.name)"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label-for="email-input"
          invalid-feedback="Некорректная почта"
        >
          <b-form-input
            id="email-input"
            placeholder="Email"
            v-model="$v.item.email.$model"
            :state="validateState($v.item.email)"
            required
          ></b-form-input>
       </b-form-group>

       <b-form-group
         class="position-relative"
         id="select-input"
         label-for="select-input"
         invalid-feedback="Выберите тему"
       >
         <b-form-input
           readonly
           id="select-input"
           @click="list = !list"
           placeholder="Выберите тему"
           required
           class="select"
           :class="{ active: list }"
           type="text"
           v-model="$v.item.subject.$model"
           :state="validateState($v.item.subject)"
         ></b-form-input>

         <div class="select-list"
              v-if="list">
           <div class=""
                v-for="option in options"
                :key="option.name"
                @click="setSubject(option.name)"
                ref="optionText">
             {{ option.name }}
           </div>
         </div>

         <img src="@/assets/svg/carpet-down.svg" class="select-icon" v-if="!list" />

         <img src="@/assets/svg/carpet-down.svg" class="select-icon select-icon__top" v-if="list" />
       </b-form-group>


        <b-form-group
          class="position-relative"
          id="textarea"
          label-for="textarea"
          invalid-feedback="Текст сообщения"
        >
          <b-form-textarea
            id="textarea"
            placeholder="Текст сообщения"
            rows="2"
            class="textarea"
            max-rows="6"
            invalid-feedback="Текст сообщения"
            v-model="$v.item.msg.$model"
            :state="validateState($v.item.msg)"
            required
          ></b-form-textarea>
        </b-form-group>

        <div class="w-100 d-flex justify-content-center captcha mt-2 mb-3">
          <vue-recaptcha sitekey="6LeNLR4oAAAAAPGxcVanZQb5iBWpWjCCE8Let1Qg"
                         :loadRecaptchaScript="true"
                         class="captcha"
                         size="100%"
                         ref="recaptcha"
                         @verify="onCaptchaVerified"
                         v-model="$v.item.recaptchaResponse.$model"
                         :state="validateState($v.item.recaptchaResponse)"
          ></vue-recaptcha>
        </div>
      </form>

      <div class="button-wrapper">
        <button class="button button-brown"
                type="submit"
                onclick="ym(94957508,'reachGoal','feedback')"
                :class="{ 'disabled' : disabledBtn }"
                :disabled="disabledBtn"
                @click="submit">
          Отправить
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import VueRecaptcha from 'vue-recaptcha'
import { validationMixin } from "vuelidate"
import { mapGetters, mapMutations } from "vuex"
import { required, minLength, maxLength, or, helpers } from "vuelidate/lib/validators"

const text = helpers.regex('text', /^[?!,.а-яА-ЯёЁ\s]+$/)
const email = helpers.regex('email', /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)

  export default {
    components: {
      VueRecaptcha
    },

    mixins: [validationMixin],

    validations: {
      item: {
        name: {
          required,
          minLength: minLength(2),
          text,
        },
        email: {
          email,
          required,
          minLength: minLength(2),
        },
        msg: {
          required,
          minLength: minLength(10)
        },
        subject: {
          required,
        },
        recaptchaResponse: {

        }
      }
    },

    data() {
      return {
        item: {
          name: '',
          email: '',
          msg: '',
          subject: '',
          recaptchaResponse: ''
        },
        recaptcha: null,
        list: '',
        options: [],
      }
    },

    mounted() {
      this.getOptions()
    },

    computed: {
      ...mapGetters({
        token: 'token'
      }),

      disabledBtn(){
        if ((this.$v.item.$anyError) || (this.item.name.length < 1) || (this.item.email.length < 1) || (this.item.msg.length < 1))
          return true
      }
    },

    methods: {
      ...mapMutations({
        getSuccessText: 'getSuccessText',
        getErrorText: 'getErrorText'
      }),

      getOptions(){
        axios.get('feedback/topics', {
          headers: {
            'Accept': "application/json",
            'Content-Type': "application/json",
            'access-control-allow-origin': "*"
          }
        }).then((response) => {
          this.options = response.data.data
        })
      },

      setSubject(optionText) {
        this.item.subject = optionText

        this.list = false
      },

      closeModal(){
        this.$v.$reset()

        this.item.name = '',
        this.item.email = '',
        this.item.msg = '',
        this.item.subject = null,
        this.item.policies = ''
      },

      validateState(item) {
        const { $dirty, $error } = item
        return $dirty ? !$error : null
      },

      onCaptchaVerified(recaptchaToken) {
        this.item.recaptchaResponse = recaptchaToken
      },

      submit() {
        this.$v.item.$touch()

        if (this.$v.item.$anyError)
          return false

        axios.post('feedback/send', {
          name: this.item.name,
          email: this.item.email,
          subject: this.item.subject,
          text: this.item.msg,
          "g-recaptcha-response": this.item.recaptchaResponse
        }).then((response) => {
          this.closeModal()

          this.getSuccessText("Спасибо! Ваше обращение отправлено, ожидайте ответ в ближайшее время.")
          this.$bvModal.show('success-modal')
        }).catch((error) => {
          if (error.response.data.message){
            this.getErrorText(error.response.data.message)

            this.closeModal()
            this.$bvModal.show('error-modal')
          }
        })
      },
    }
  }
</script>

<style scoped lang="scss">
.textarea {
  margin-top: 20px;
  min-height: 96px;
  padding: 15px 25px;
  border-radius: 20px !important;

  &::placeholder {
    color: #6882B9 !important;
  }
}

.form-control {
  font-weight: 400;

  &::placeholder {
    color: #6882B9 !important;
  }
}

.dropdown-button {
  width: 100%;
  background-color: $blue !important;
}

.select {
  height: 38px;
  width: 100%;
  position: relative;
  background-color: $blue;
  margin-bottom: 20px;
  margin-top: 5px;
  transition: .1s all !important;

  &.active {
    border: 1px solid $blue;
  }
}

.select-icon {
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 15px;
  margin-top: 15px;

  &__top {
    transform: rotate(180deg);
  }
}

.select-list {
  position: absolute;
  margin-top: -20px !important;
  width: 100%;
  z-index: 99999;
  border-top: none;
  border: 1px solid $blue;
  border-radius: 0 0 25px 25px;
  background-color: $blue;

  div {
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 25px;
    background-color: $white;
    cursor: pointer;
    transition: .5s all;
    font-family: $regular;
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    color: $darker-grey;
    border: 1px solid white !important;

    &:last-child {
      border-radius: 0 0 25px 25px;
    }

    &.active {
      background-color: $blue;
      color: $red;
    }

    &:hover {
      opacity: .8;
    }
  }
}

.button-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
}

.button {
  margin-top: 10px;
  width: 100%;
  max-width: 165px;

  &.disabled {
    pointer-events: none;
  }

  &:hover {
    opacity: 0.8 !important;
  }
}

.subtext {
  color: $white;
  text-align: center;
  font-family: $regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 25px;
  line-height: 120%; /* 16.8px */

  @media(max-width: 768px){
    font-size: 12px;
  }
}

.captcha {
  margin-top: 0;
  transform: none;
  justify-content: flex-start;

  @media(max-width: 576px){
    transform: scale(90%);
    justify-content: flex-start !important;
  }
}

.dropdown-button {
  width: 100%;
  background-color: $light-grey !important;
}

.select {
  height: 38px;
  width: 100%;
  position: relative;
  background-color: $light-grey;
  margin-bottom: 20px;
  transition: .1s all !important;

  &.active {
    border: 1px solid #cecece;
    border-bottom: none !important;
    border-radius: 25px 25px 0 0 !important;
  }
}

.select-list {
  position: absolute;
  margin-top: -18px;
  width: 100%;
  z-index: 99999;
  border-top: none;
  border-radius: 0 0 25px 25px;
  background-color: $white;

  div {
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 15px;
    background-color: $white;
    border-radius: 0 0 25px 25px;
    cursor: pointer;
    transition: .5s all;
    font-family: $regular;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    color: $black;

    &.active {
      background-color: $white;
      color: $red;
    }

    &:hover {
      background-color: $white;
      color: $red;
    }
  }
}

.form-group {
  margin-bottom: 12px !important;
}

.content-modal {
  background-color: #02BA44;
  border-radius: 50px;
  padding: 0 70px;
  padding-top: 20px;
  padding-bottom: 20px;

  @media(max-width: 999px){
    padding: 0 30px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.form-control[readonly] {
  background: white !important;
}

.button-brown {
  margin-top: -20px;
  background-color: #392A25;
  color: $white;
}
</style>
