<template>
  <div>
    <b-modal
      id="password-sent-modal"
      ref="modal"
      title=""
      hide-footer
      hide-header
      centered
    >
      <div class="header">
        <span class="close-icon"
              @click="$bvModal.hide('password-sent-modal')">
          <img src="@/assets/svg/close.svg"
               aria-label="close">
        </span>

        <h5>
          Пароль отправлен
        </h5>
      </div>

      <div>
        <div class="text">
          Ваш пароль для входа был отправлен на <br>
          электронную почту, указанную при регистрации <br><br>

          <span style="font-weight: 900">E-mail: {{ this.email }}</span>
        </div>

        <div class="option" @click="resend">
          Отправить повторно
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
import { mapState, mapGetters, mapMutations } from "vuex"

  export default {
    data() {
      return {
      }
    },

    computed: {
      ...mapGetters({
        successText: 'successText',
        email: 'email'
      }),
    },

    methods: {
      resend(){
        axios.post('auth/forgot/resend', {
          email: this.email,
        }).then((response) => {

          this.$bvModal.hide('password-sent-modal')
        }).catch((error) => {
          this.$bvModal.hide('password-sent-modal')
        })
      }
    }
  }
</script>

<style scoped lang="scss">
.header {
  flex-direction: column;
  width: 100%;

  .close-icon {
    float: right;
    cursor: pointer;
    transition: .4s all;
    margin-right: 5px;

    &:hover {
      opacity: .85;
    }
  }

  h5 {
    width: 100%;
    color: $white;
    margin-top: 70px !important;
    margin-bottom: 30px;
    font-weight: 800;
    font-family: $regular;
    font-size: 22px;
    text-transform: uppercase;
    text-align: center;
    padding-bottom: 0px;
  }
}

.text {
  text-align: center;
  color: $white;
  font-family: $regular;
  font-size: 15px;
  line-height: 120%;
}

.option {
  text-decoration: underline;
  color: white;
  text-align: center;
  margin-top: 50px;
  cursor: pointer;
  padding-bottom: 10px;
}
</style>
