<template>
  <div>
    <b-modal
      id="plug-modal"
      ref="modal"
      title=""
      hide-footer
      hide-header
      centered
    >
      <div class="header">
        <span class="close-icon"
              @click="$bvModal.hide('plug-modal')">
          <img src="@/assets/svg/close.svg"
               aria-label="close">
        </span>

        <h3>
          Зарегистрировать <br> чек
        </h3>

        <div class="content-modal">
          <button @click="openVoucher"
                  class="button button-green"
                  onclick="ym(92028230,'reachGoal','x5paket-tc5-mk-site')"
                  v-if="token">
            На сайте
          </button>

          <button v-b-modal.enter-modal
                  @click="$bvModal.hide('plug-modal')"
                  class="button button-green"
                  v-if="!token">
            На сайте
          </button>

          <a href="https://t.me/Paket5ka_bot"
             target="_blank"
             @click="$bvModal.hide('plug-modal')"
             class="button button-blue mt-4">
            В Telegram
          </a>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex"

  export default {
    data() {
      return {
      }
    },

    computed: {
      ...mapGetters({
        errorText: 'errorText',
        token: 'token'
      }),
    },

    methods: {
      openVoucher(){
        this.$bvModal.hide('plug-modal')
        this.$bvModal.show('voucher-modal')
      }
    }
  }
</script>

<style scoped lang="scss">
.header {
  flex-direction: column;
  width: 100%;

  .close-icon {
    float: right;
    cursor: pointer;
    transition: .4s all;
    margin-right: 5px;

    &:hover {
      opacity: .85;
    }
  }
}
</style>
