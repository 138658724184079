<template>
  <div>
    <b-modal
      id="enter-modal"
      ref="modal"
      title=""
      hide-footer
      hide-header
      centered
    >
      <div class="header">
        <span class="close-icon"
              @click="$bvModal.hide('enter-modal')">
          <img src="@/assets/svg/close.svg"
               aria-label="close">
        </span>

        <h3>
          Для участия в Акции <br> необходимо
        </h3>
      </div>

      <div class="content-modal">
        <button class="button button-white"
                type="submit"
                v-b-modal.login-modal
                @click="$bvModal.hide('enter-modal')">
          Войти
        </button>

        <button class="button button-red mt-4"
                type="submit"
                v-b-modal.registration-modal
                @click="$bvModal.hide('enter-modal')">
          Зарегистрироваться
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex"

  export default {
    data() {
      return {
      }
    },

    computed: {
      ...mapGetters({
      }),
    },
  }
</script>

<style scoped lang="scss">
.header {
  flex-direction: column;
  width: 100%;

  .close-icon {
    float: right;
    cursor: pointer;
    transition: .4s all;
    margin-right: 5px;

    @media(max-width: 768px){
      margin-right: 5px !important;
    }

    &:hover {
      opacity: .85;
    }
  }
}
</style>
