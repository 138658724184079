<template>
  <footer class="footer-wrapper">
    <div>
      <div class="copyright">
        2023 Danone Ltd. Все права защищены
      </div>

      <div class="action-panel">
        <a :href="rules[0].url"
           v-if="rules.length > 0"
           target="_blank">
          Правила акции
        </a>

        <a :href="policies[0].url"
           v-if="policies.length > 0"
           target="_blank">
          Политика конфиденциальности
        </a>

        <a :href="personalData[0].url"
           v-if="personalData.length > 0"
           target="_blank">
          Пользовательское соглашение
        </a>
      </div>
    </div>

    <div class="text-wrapper">
      Общий срок проведения акции с 18.09.2023 по 15.11.2023. Срок совершения
      покупок и регистрации чеков с 18.09.2023 по 15.10.2023. <br>
      Срок определения победителей и вручения призов с 18.09.2023 по 15.11.2023.
      Подробная информация об организаторе, правилах проведения, сроках,
      количестве призов, месте и порядке их получения на сайте
      <a href="https://xn--5-8sb3a.xn--80acubh1ay.xn--p1ai"
         target="_blank">
        https://5ка.актибио.рф
      </a>
    </div>
  </footer>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: 'FooterRegion',

  data: () => ({
  }),

  mounted(){
    this.$store.dispatch('getDocuments')
  },

  computed: {
    ...mapGetters({
      policies: 'policies',
      rules: 'rules',
      personalData: 'personalData'
    }),
  },
}
</script>

<style scoped lang="scss">
.footer-wrapper {
  font-family: $regular;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  padding: 35px;

  @media(max-width: 876px){
    padding-bottom: 95px;
  }

  @media(max-width: 768px){
    flex-direction: column;
    padding: 25px 15px;
    padding-bottom: 95px;
  }

  .copyright {
    @media(max-width: 768px){
      margin-bottom: 20px;
    }

    @media(max-width: 576px){
      font-size: 14px;
      text-align: center;
    }
  }

  .action-panel {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: 400;

    @media(max-width: 768px){
      flex-direction: column;
    }

    @media(max-width: 576px){
      font-size: 14px;
      text-align: center;
    }

    a {
      color: $light-green;
      text-decoration: underline;
      white-space: nowrap;
      margin-top: 20px;

      @media(max-width: 768px){
        margin-top: 0;
      }

      &:first-child {
        margin-right: 15px;

        @media(max-width: 768px){
          margin-bottom: 10px;
          margin-top: 10px;
          margin-left: 0;
        }
      }

      &:last-child {
        margin-left: 15px;

        @media(max-width: 768px){
          margin-bottom: 20px;
          margin-top: 10px;
          margin-left: 0;
        }
      }

      &:hover {
        color: $red;
      }

      @media(max-width: 768px){
        margin-left: 0;
        text-align: center;
      }
    }
  }
}

.text-wrapper {
  background-color: white;
  color: #392A25;
  font-family: $regular;
  font-size: 12px;
  line-height: 130%;
  font-family: 400;
  max-width: 740px;
  text-align: left;

  @media(max-width: 999px){
    br {
      display: none;
    }
  }

  @media(max-width: 450px){
    font-size: 8px;
  }

  a {
    color: $light-green;
    transition: .7s all;
    text-decoration: none;

    &:hover {
      color: $red;
      transition: .7s all;
    }
  }
}
</style>
