<template>
  <div class="home">
    <Promo />

    <Participate />

    <Prizes />

    <Products />

    <Buy />

    <FaqSection />
  </div>
</template>

<script>
import Buy from '@/components/Buy'
import Promo from '@/components/Promo'
import Prizes from '@/components/Prizes'
import Products from '@/components/Products'
import FaqSection from '@/components/FaqSection'
import Participate from '@/components/Participate'

export default {
  name: 'HomeView',
  components: {
    Buy,
    Promo,
    Prizes,
    Products,
    FaqSection,
    Participate
  },
  mounted(){
  }
}

</script>
