<template>
  <div class="home">
    <Faq />
  </div>
</template>

<script>
import Faq from '@/components/Faq'

export default {
  name: 'FAQ',
  components: {
    Faq
  }
}
</script>
