<template>
  <div>
    <b-modal
      id="login-modal"
      ref="modal"
      title=""
      hide-footer
      hide-header
      no-close-on-backdrop
      centered
    >
      <div class="header">
        <span class="close-icon"
              @click="closeModal">
          <img src="@/assets/svg/close.svg"
               aria-label="close">
        </span>

        <h3>
          Авторизация
        </h3>
      </div>

      <div class="w-100 content-modal">
        <form ref="form"
              class="form w-100 modal-form"
              @submit.stop.prevent="submit">
          <b-form-group
            label-for="email-input"
            :invalid-feedback="invalidEmail"
          >
            <b-form-input
              id="email-input"
              placeholder="E-mail"
              v-model="$v.item.email.$model"
              :class="{ 'is-invalid' : isInvalidEmail }"
              :state="validateState($v.item.email)"
              required
            ></b-form-input>
         </b-form-group>

         <b-form-group
           label-for="password-input"
           class="position-relative"
           :invalid-feedback="invalidPassword"
         >
         <b-form-input
           id="password-input"
           placeholder="Пароль*"
           v-model="$v.item.password.$model"
           :class="{ 'is-invalid' : isInvalidPassword }"
           :state="validateState($v.item.password)"
           required
           type="password"
         ></b-form-input>
         </b-form-group>

         <div class="w-100 d-flex justify-content-center captcha mt-2">
             <vue-recaptcha sitekey="6LeNLR4oAAAAAPGxcVanZQb5iBWpWjCCE8Let1Qg"
                            :loadRecaptchaScript="true"
                            class="captcha"
                            size="100%"
                            ref="recaptcha"
                            @verify="onCaptchaVerified"
                            v-model="$v.item.recaptchaResponse.$model"
                            :state="validateState($v.item.recaptchaResponse)"
             ></vue-recaptcha>
           </div>
        </form>

        <div class="options-wrapper">
          <div class="option"
               v-b-modal.reset-modal
               @click="$bvModal.hide('login-modal')">Забыли пароль?</div>

          <div class="option"
               v-b-modal.info-modal
               @click="$bvModal.hide('login-modal')">Зарегистрироваться</div>
        </div>

        <button class="button button-red"
                 type="submit"
                 onclick="ym(94957508,'reachGoal','authorization')"
                 :class="{ 'disabled' : disabledBtn }"
                 @click="submit">
            Войти
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
import VueRecaptcha from 'vue-recaptcha'
import { validationMixin } from "vuelidate"
import { mapGetters, mapMutations } from "vuex"
import { required, requiredIf, minLength, maxLength, or, helpers } from "vuelidate/lib/validators"

const email = helpers.regex('email', /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)

export default {
  mixins: [validationMixin],

  components: {
    VueRecaptcha
  },

  validations: {
    item: {
      email: {
        required,
        minLength: minLength(4),
        email
      },
      password: {
        required,
      },
      remember: {

      },
      recaptchaResponse: {
      }
    }
  },

  data() {
    return {
      item: {
        email: '',
        password: '',
        recaptchaResponse: '',
      },
      recaptcha: null,
      isInvalidEmail: false,
      isInvalidPassword: false,
      invalidEmail: 'Некорректное поле',
      invalidPassword: 'Некорректное поле',
    }
  },

  computed: {
    disabledBtn(){
      if ((this.$v.item.$anyError) || (this.item.password.length < 1) || (this.item.email.length < 1) || (this.item.recaptchaResponse.length < 1))
        return true
    }
  },

  methods: {
    ...mapMutations({
      saveToken: 'saveToken',
      saveEmail: 'saveEmail',
      getSuccessText: 'getSuccessText',
      getErrorText: 'getErrorText'
    }),

    closeModal() {
      this.$v.$reset()
      this.$bvModal.hide('login-modal')

      this.item.email = '',
      this.item.password = '',
      this.isInvalidEmail = false,
      this.isInvalidPassword = false,
      this.item.recaptchaResponse = ''
    },

    validateState(item) {
      const { $dirty, $error } = item
      return $dirty ? !$error : null
    },

    onCaptchaVerified(recaptchaToken) {
      this.item.recaptchaResponse = recaptchaToken
    },

    submit() {
      this.$v.item.$touch()

      this.isInvalidPassword = false

      if (this.$v.item.$anyError)
        return false

      axios.post('auth/login', {
        email: this.item.email,
        password: this.item.password,
        "g-recaptcha-response": this.item.recaptchaResponse
      }, {
        headers: {
          'Accept': "application/json",
          'Content-Type': "application/json",
          'access-control-allow-origin': "*",
          'Authorization': ''
        }
      }).then((response) => {
        this.closeModal()

        axios.defaults.headers.authorization = "Bearer " + response.data.access.token

        this.saveToken(response.data.access.token)
        localStorage.setItem('token', response.data.access.token)

        setTimeout(() => {
          this.$store.dispatch('getUser')
          this.$store.dispatch('getReceipts')
          this.$store.dispatch('getPrizes')
          this.$router.push('/office')
        })
      }).catch((error) => {
        if (error.response.data.message){
          this.isInvalidPassword = true,
          // this.isInvalidEmail = true

          // this.invalidEmail = error.response.data.message
          this.invalidPassword = error.response.data.message
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.header {
  flex-direction: column;
  width: 100%;

  .close-icon {
    float: right;
    cursor: pointer;
    transition: .4s all;
    margin-right: 5px;

    @media(max-width: 768px){
      margin-right: 5px !important;
    }

    &:hover {
      opacity: .85;
    }
  }

  h5 {
    width: 100%;
    color: $darker-grey;
    margin-top: 70px !important;
    margin-bottom: 30px;
    font-weight: 500;
    font-family: $regular;
    font-size: 32px;
    text-transform: uppercase;
    text-align: center;
    padding-bottom: 0px;

    @media(max-width: 768px){
      font-size: 24px;

      br {
        display: block;
      }
    }
  }
}

.content {
  padding: 0 10px;
  margin: 0;
}

.button {
  width: fit-content;
}

.button-wrapper {
  display: flex;
  width: 100%;
  margin-bottom: 30px;
  justify-content: center;
  align-items: center;

  .button {
    height: 42px;
    width: 100%;
    width: fit-content;
  }
}

.options-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 45px;
  margin-top: -5px;
  width: 100%;
  max-width: 320px;

  .option {
    text-decoration: underline;
    transition: .4s all;
    font-size: 14px;
    font-family: $regular;
    color: $white;
    margin-bottom: -10px;
  }
}

.modal-form {
  max-width: 320px;
  margin-top: 30px;
}

.icon {
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 25px;
  cursor: pointer;
  margin-right: 15px;
  max-width: 25px;
}

.captcha {
  transform: none;
  justify-content: center;
  margin-bottom: 10px;
}
</style>
