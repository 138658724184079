<template>
  <section class="promo-wrapper" id="faq">
    <h1 class="section-title">Часто <br> задаваемые <br> вопросы</h1>

    <div class="content">
      <div class="faq-wrapper">
        <Faq />
      </div>

      <div class="feedback-wrapper">
        <Feedback />
      </div>
    </div>
  </section>
</template>

<script>
import Faq from '@/components/Faq'
import { mapGetters, mapMutations } from "vuex"
import Feedback from '@/components/modals/Feedback'

export default {
  name: 'FaqSection',

  components: {
    Faq,
    Feedback,
  },

  data: () => ({
  }),

  computed: {
  },

  methods: {
  }
}
</script>

<style scoped lang="scss">
.promo-wrapper {
  background-image: url('@/assets/svg/pattern@big.svg');
  background-size: cover;
  font-family: $regular;
  background-color: $white;
  background-repeat: no-repeat;
  padding-top: 90px;
  background-position: top center;
  padding-bottom: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media(max-width: 768px){
    padding: 50px 20px;
    background-image: url('@/assets/svg/pattern@mobile.svg');
  }


  .faq-wrapper {
    width: 100%;
    max-width: 800px;
    max-height: 515px;
    overflow-y: scroll;

    @media(max-width: 1600px){
      max-width: 690px;
    }

    @media(max-width: 999px){
      max-width: 340px;
    }

    @media(max-width: 760px){
      max-width: none;
      margin-bottom: 35px;
    }
  }

  .section-title {
    color: #392A25;
    font-size: 48px;

    br {
      display: none;
    }

    @media(max-width: 999px){
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 900;
      line-height: 20px; /* 83.333% */
    }

    @media(max-width: 576px){
      font-size: 34px;
      line-height: 36px;

      br {
        display: block;
      }
    }
  }

  .content {
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-top: 60px;
    justify-content: space-between;

    @media(max-width: 760px){
      position: relative;
      flex-direction: column;
      align-items: center;
      margin-top: 20px;
    }
  }
}

.disabled {
  pointer-events: none;
  filter: grayscale(100%);
}
</style>
