<template>
  <div>
    <b-modal
      id="info-modal"
      ref="modal"
      title=""
      hide-footer
      hide-header
      centered
    >
      <div class="header">
        <span class="close-icon"
              @click="$bvModal.hide('info-modal')">
          <img src="@/assets/svg/close.svg"
               aria-label="close">
        </span>

        <h3>АКЦИЯ ЗАВЕРШЕНА!
        </h3>
      </div>

      <div class="content-modal">
        <div class="subtext">
          Поздравляем победителей и&nbsp;благодарим всех,<br> кто принял участие.
        </div>

        <!-- <router-link to="/office"
                     class="button button-green"
                     @click.native="$bvModal.hide('thanks-modal')">Загрузить еще</router-link> -->
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
import { mapState, mapGetters, mapMutations } from "vuex"

  export default {
    data() {
      return {
      }
    },

    computed: {
      ...mapGetters({
        successText: 'successText'
      }),
    },

    methods: {
    }
  }
</script>

<style scoped lang="scss">
.header {
  flex-direction: column;
  width: 100%;

  .close-icon {
    float: right;
    cursor: pointer;
    transition: .4s all;
    margin-right: 5px;

    &:hover {
      opacity: .85;
    }
  }

  h3 {
    font-size: 32px !important;
    text-transform: uppercase;
  }
}

.subtext {
  text-align: center;
  color: $darker-grey;
  font-size: 16px;
  font-weight: 450;
  font-family: $regular;
  color: $white;
  margin-top: 10px;
  padding-bottom: 10px;

  @media(max-width: 768px){
    font-size: 14px;
  }
}

.img {
  max-width: 85px;
  margin-bottom: 20px;
}

.button {
  color: $white !important;

  &:hover {
    text-decoration: none;
  }
}
</style>
