<template>
    <b-modal
      id="verification-modal"
      ref="modal"
      title=""
      hide-footer
      hide-header
      class="ver-modal"
    >
      <div class="header">
        <span class="close-icon"
              @click="closeModal">
          <img src="@/assets/svg/close.svg"
               aria-label="close">
        </span>

        <h3>
          Поздравляем!
        </h3>

        <div class="subtext">
          Для получения приза, пожалуйста, <br> заполните информацию ниже:
        </div>
      </div>

      <form ref="form"
            class="modal-form"
            @submit.stop.prevent="submit">
        <b-form-group
          id="name-input"
          label-for="name-input"
          :invalid-feedback="invalidName"
        >
          <b-form-input
            id="name-input"
            placeholder="ФИО"
            required
            type="text"
            pattern="[A-Za-z]+"
            :class="{ 'is-invalid' : isInvalidName }"
            v-model="$v.item.name.$model"
            :state="validateState($v.item.name)"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label-for="birthdate-input"
          v-mask="'##.##.####'"
          :invalid-feedback="invalidBirthdate"
        >
          <b-form-input
            id="birthdate-input"
            placeholder="Дата рождения"
            v-model="$v.item.birthdate.$model"
            maxlength="10"
            :state="validateState($v.item.birthdate)"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label-for="inn-input"
          :invalid-feedback="invalidInn"
        >
          <b-form-input
            id="inn-input"
            placeholder="Номер ИНН"
            v-model="$v.item.inn.$model"
            ref="inn"
            maxLength="20"
            :class="{ 'is-invalid' : isInvalidInn, 'is-valid' : !isInvalidInn }"
            :state="validateState($v.item.inn)"
            required
          ></b-form-input>
       </b-form-group>

        <b-form-group
          id="series-input"
          label-for="series-input"
          v-mask="'#### ######'"
          :invalid-feedback="invalidSeries"
        >
          <b-form-input
            id="series-input"
            placeholder="Серия и номер паспорта"
            required
            ref="series"
            maxLength="11"
            v-model="$v.item.series.$model"
            :class="{ 'is-invalid' : isInvalidSeries, 'is-valid' : !isInvalidSeries }"
            :state="validateState($v.item.series)"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label-for="issued-by-input"
          :invalid-feedback="invalidIssuedBy"
        >
          <b-form-input
            id="issued-by-input"
            placeholder="Кем выдан"
            v-model="$v.item.issuedBy.$model"
            :state="validateState($v.item.issuedBy)"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label-for="issued-input"
          v-mask="'##.##.####'"
          :invalid-feedback="invalidIssued"
        >
          <b-form-input
            id="issued-input"
            placeholder="Дата выдачи паспорта"
            v-model="$v.item.issued.$model"
            maxlength="10"
            :state="validateState($v.item.issued)"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label-for="home-address-input"
          :invalid-feedback="invalidHomeAddress"
        >
          <b-form-input
            id="home-address-input"
            placeholder="Адрес регистрации (прописка)"
            required
            v-model="$v.item.homeAddress.$model"
            :state="validateState($v.item.homeAddress)"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label-for="address-input"
          :invalid-feedback="invalidAddress"
        >
          <b-form-input
            id="address-input"
            placeholder="Адрес доставки"
            v-model="$v.item.address.$model"
            :state="validateState($v.item.address)"
            required
          ></b-form-input>
        </b-form-group>

       <label>
         <input type="file"
                @change="fileChangePassportFirstPage"
                accept="image/jpeg, image/png, image/jpg, image/bmp">

         <span class="fake-button fake-button-red">Загрузить скан/фото паспорта 1-й разворот</span>
       </label>

       <div class="">
          <div class="uploaded-img"
               v-for="(image, index) in passportFirstPage"
               :key="index">
            {{ image.name }}

            <div class="delete">
              <img @click.prevent="deleteImageFirst(index)"
                   src="@/assets/svg/close.svg"
                   alt="delete">
            </div>
          </div>
       </div>

       <div class="error"
            v-if="isInvalidFirstPage">
         {{ invalidFirstPage }}
       </div>

       <label>
         <input type="file"
                @change="fileChangePassportSecondPage"
                accept="image/jpeg, image/png, image/jpg, image/bmp">

         <span class="fake-button fake-button-red">Загрузить скан/фото паспорта (прописка)</span>
       </label>

       <div class="">
          <div class="uploaded-img"
               v-for="(image, index) in passportSecondPage"
               :key="index">
            {{ image.name }}

            <div class="delete">
              <img @click.prevent="deleteImageSecond(index)"
                   src="@/assets/svg/close.svg"
                   alt="delete">
            </div>
          </div>
       </div>

       <div class="error"
            v-if="isInvalidSecondPage">
         {{ invalidSecondPage }}
       </div>

       <label>
         <input type="file"
                @change="fileChangeDocumentInn"
                accept="image/jpeg, image/png, image/jpg, image/bmp">

         <span class="fake-button fake-button-red">Загрузить скан/фото ИНН</span>
       </label>

       <div class="">
          <div class="uploaded-img"
               v-for="(image, index) in documentInn"
               :key="index">
            {{ image.name }}

            <div class="delete">
              <img @click.prevent="deleteImageInn(index)"
                   src="@/assets/svg/close.svg"
                   alt="delete">
            </div>
          </div>
       </div>

       <div class="error"
            v-if="isInvalidFileInn">
         {{ invalidFileInn }}
       </div>
    </form>

    <b-button class="button button-red"
              type="submit"
              :disabled="disabledBtn"
              @click="submit">Отправить</b-button>
  </b-modal>
</template>

<script>
import axios from 'axios'
import { validationMixin } from "vuelidate"
import { mapGetters, mapMutations } from "vuex"
import { required, requiredIf, minLength, maxLength, or, helpers } from "vuelidate/lib/validators"

const text = helpers.regex('text', /^[?!,.а-яА-ЯёЁ\-\s]+$/)
const email = helpers.regex('email', /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)
const number = helpers.regex('number', /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/)
const token = localStorage.getItem('token')

  export default {
    mixins: [validationMixin],

    validations: {
      item: {
        name: {
          required,
          minLength: minLength(2),
          text,
        },
        birthdate: {
          required
        },
        series: {
          required,
          minLength: minLength(11),
          maxLength: maxLength(11),
          number,
        },
        inn: {
          required,
          minLength: minLength(12),
          maxLength: maxLength(12),
          number,
        },
        issuedBy: {
          required,
        },
        issued: {
          required
        },
        homeAddress: {
          required
        },
        address: {
          required
        }
      }
    },

    data() {
      return {
        item: {
          name: '',
          birthdate: '',
          series: '',
          inn: '',
          issuedBy: '',
          issued: '',
          address: '',
          homeAddress: ''
        },
        documentInn: [],
        documentPhoto: [],
        documentVoucher: [],
        passportFirstPage: [],
        passportSecondPage: [],
        isInvalidName: false,
        isInvalidSeries: false,
        isInvalidInn: false,
        isInvalidPhone: false,
        isInvalidAddress: false,
        isInvalidIndex: false,
        isInvalidHomeAddress: false,
        isInvalidFirstPage: false,
        isInvalidSecondPage: false,
        isInvalidFileInn: false,
        isInvalidFilePhoto: false,
        invalidName: 'Некорректное поле',
        invalidSeries: 'Некорректное поле',
        invalidNumber: 'Некорректное поле',
        invalidInn: 'Некорректное поле',
        invalidPhone: 'Некорректное поле',
        invalidIndex: 'Некорректное поле',
        invalidIssuedBy: 'Некорректное поле',
        invalidIssued: 'Некорректное поле',
        invalidBirthdate: 'Некорректное поле',
        invalidAddress: 'Некорректное поле',
        invalidHomeAddress: 'Некорректное поле',
        invalidFirstPage: '',
        invalidSecondPage: '',
        invalidFileInn: '',
        invalidFilePhoto: ''
      }
    },

    computed: {
      ...mapGetters({
        token: 'token',
        prizeUuid: 'prizeUuid'
      }),

      disabledBtn(){
        if (this.$v.item.$anyError || this.documentInn.length < 1 ||  this.passportFirstPage.length < 1 || this.passportSecondPage.length < 1){
          return true
        }
      }
    },

    mounted(){
    },

    methods: {
      ...mapMutations({
        saveUser: 'saveUser',
        saveToken: 'saveToken',
        getSuccessText: 'getSuccessText'
      }),

      resetModal() {
        this.$bvModal.hide('verification-modal')

        this.$v.$reset()

        this.item.name = '',
        this.item.series = '',
        this.item.inn = '',
        this.item.birthdate = '',
        this.item.issued = '',
        this.item.issuedBy = '',
        this.item.address = '',
        this.item.homeAddress = '',
        this.isInvalidName = false,
        this.isInvalidSeries = false,
        this.isInvalidInn = false,
        this.isInvalidFirstPage = false,
        this.isInvalidSecondPage = false,
        this.isInvalidFileInn = false,
        this.invalidName = 'Некорректное поле',
        this.invalidSeries = 'Некорректное поле',
        this.invalidNumber = 'Некорректное поле',
        this.invalidInn = 'Некорректное поле',
        this.invalidFirstPage = '',
        this.invalidSecondPage = '',
        this.invalidFileInn = '',
        this.documentInn = [],
        this.passportFirstPage = [],
        this.passportSecondPage = []
      },

      closeModal(){
        this.$v.$reset()

        this.item.name = '',
        this.item.series = '',
        this.item.number = '',
        this.item.inn = '',
        this.item.birthdate = '',
        this.item.issued = '',
        this.item.issuedBy = '',
        this.item.address = '',
        this.item.homeAddress = '',
        this.item.bankName = '',
        this.item.bankCode = '',
        this.item.bankAccount = '',
        this.item.userAccount = '',
        this.isInvalidName = false,
        this.isInvalidSeries = false,
        this.isInvalidInn = false,
        this.isInvalidFirstPage = false,
        this.isInvalidSecondPage = false,
        this.isInvalidFileInn = false,
        this.invalidName = 'Некорректное поле',
        this.invalidIssued = 'Некорректное поле',
        this.invalidBirthdate = 'Некорректное поле',
        this.invalidSeries = 'Некорректное поле',
        this.invalidNumber = 'Некорректное поле',
        this.invalidInn = 'Некорректное поле',
        this.invalidFirstPage = '',
        this.invalidSecondPage = '',
        this.invalidFileInn = '',
        this.documentInn = [],
        this.passportFirstPage = [],
        this.passportSecondPage = []

        this.$bvModal.hide('verification-modal')
      },

      fileChangePassportFirstPage(event){
        Array.from(event.target.files).forEach(value => {
          this.passportFirstPage.push(value)
        })

        this.isInvalidFirstPage = false
      },

      fileChangePassportSecondPage(event){
        Array.from(event.target.files).forEach(value => {
          this.passportSecondPage.push(value)
        })

        this.isInvalidSecondPage = false
      },

      fileChangeDocumentInn(event){
        Array.from(event.target.files).forEach(value => {
          this.documentInn.push(value)
        })

        this.isInvalidFileInn = false
      },

      deleteImageFirst(index){
        this.passportFirstPage.splice(index, 1)

        this.isInvalidFirstPage = false
      },

      deleteImageSecond(index){
        this.passportSecondPage.splice(index, 1)

        this.isInvalidSecondPage = false
      },

      deleteImageInn(index){
        this.documentInn.splice(index, 1)

        this.isInvalidFileInn = false
      },

      validateState(item) {
        const { $dirty, $error } = item
        return $dirty ? !$error : null
      },

      submit() {
        this.$v.item.$touch()

        if (this.$v.item.$anyError)
          return false

          const formData = new FormData()
          formData.append('file_passport_one_page', this.passportFirstPage[0])
          formData.append('file_passport_two_page', this.passportSecondPage[0])
          formData.append('file_inn', this.documentInn[0])
          formData.append('fio', this.item.name)
          formData.append('passport', this.item.series)
          formData.append('inn', this.item.inn)
          formData.append('date_birth', this.item.birthdate)
          formData.append('when_issued', this.item.issued)
          formData.append('issued_by', this.item.issuedBy)
          formData.append('address', this.item.homeAddress)
          formData.append('delivery_address', this.item.address)

          axios.post('forms/passport/' + this.prizeUuid, formData, {
          }, {
            headers: {
              'Authorization': "Bearer " + localStorage.getItem('token'),
              'Accept': "application/json",
              'Content-Type': "application/json",
              'access-control-allow-origin': "*",
            }
          }).then((response) => {
            this.resetModal()

            this.$store.dispatch('getPrizes')

            this.getSuccessText(response.data.message)
            this.$bvModal.show('success-modal')
          }).catch((error) => {
            if (error.response.data.message === "Паспортные данные уже добавлены"){
              this.$emit('getErrorText', error.response.message)

              this.closeModal()
              this.$bvModal.show('error-modal')
            } if (error.response.data.message === "Token has expired"){
              this.$emit('getErrorText', error.response.message)

              this.closeModal()
              this.$bvModal.show('error-modal')
            } if (error.response.data.message && !error.response.data.errors){
              this.$emit('getErrorText', error.response.message)

              this.$bvModal.show('error-modal')
            } if (error.response.data.errors){
              this.invalidFirstPage = 'Заполните поле'
              this.invalidSecondPage = 'Заполните поле'
              this.invalidFileInn = 'Заполните поле'
            }
        })
      },
    }
  }
</script>

<style scoped lang="scss">
.button {
  margin-top: 40px;
  width: 100%;
  border: 1px solid $black !important;

  &:hover {
    opacity: 0.8 !important;
  }
}

.form-control {
  border-radius: 30px;
  border: 1px solid #ABAB9A;
  background: #F7F7F6 !important;
  transition: .6s all;
  height: 57px;
  display: flex;
  color: $black;
  padding-left: 25px !important;
  align-items: center;
  box-shadow: 0px 7px 20px 2px rgba(103, 103, 103, 0.1) !important;
  margin-top: 4px;

  &::placeholder {
    color: #626262
  }

  &:focus {
    box-shadow: none;
    border: 1px solid $black;
    box-shadow: 0px 7px 20px 2px rgba(103, 103, 103, 0.1) !important;
  }
}

.ver-modal {
  background: #F7F7F6 !important;
}

.subtext {
  color: white;
  text-align: center;
  font-family: $regular;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin-bottom: 18px;
}

.modal-form {
  width: 100%;
  max-width: 360px;
}

.modal-title {
  padding: 7px 0;
  width: 114%;
  margin-left: -30px;
  margin-top: -35px;

  @media(max-width: 576px){
    width: 100%;
    margin-left: 0;
    margin-top: 0;
    font-size: 24px;
    margin-top: 50px;
    margin-bottom: 20px;
  }
}

a {
  color: $red;
}

.button-red {
  max-width: 260px;
  height: 62px;
  border: none !important;
  box-shadow: none !important;
  font-size: 24px !important;
}

label {
  width: 100%;
  text-decoration: underline;
  margin-bottom: 7px;
  color: $white;
  cursor: pointer;
}

.fake-upload {
  color: $black;
  font-size: 18px;
  font-weight: 400;

  &:hover {
    opacity: 0.9;
  }
}

input[type='file'] {
  position: absolute;
  opacity: 0;
  height: 0;
}

.uploaded-img {
  background-color: $white;
  padding: 10px 15px;
  border-radius: 15px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.delete {
  width: 25px;
  height: 25px;
  background-color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  &:hover {
    opacity: 0.8;
  }

  img {
    max-width: 17px;
    max-height: 17px;
    background: red;
    border-radius: 50%;
  }
}

.error {
  color: $red;
  font-size: 12px;
  margin-top: -20px;
}

.fake-button {
  font-size: 14px;
  font-family: $regular;
  font-weight: 400;
  color: white;
}

.docs-left {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  margin-top: 15px;
  margin-left: -307px;
  max-height: 650px;

  @media(max-width: 768px){
    display: none;
  }
}

.docs-right {
  position: absolute;
  top: 0;
  max-height: 700px;
  z-index: -1;
  right: 0;
  margin-top: -60px;
  margin-right: -394.5px;

  @media(max-width: 768px){
    display: none;
  }
}
</style>
