<template>
  <section class="promo-wrapper" id="buy">
    <div class="content">
      <h3>Покупайте <br> с сервисом <br> «Пятёрочка <br> доставка»</h3>

      <a class="logo" href="https://5ka.onelink.me/ml1j/73C10629" target="_blank">
        <img src='@/assets/svg/5ka.svg' class="logo" />
      </a>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapMutations } from "vuex"

export default {
  name: 'Buy',

  data: () => ({
  }),

  computed: {
  },

  methods: {
  }
}
</script>

<style scoped lang="scss">
.promo-wrapper {
  background-image: url('@/assets/svg/pattern@big.svg');
  background-size: cover;
  font-family: $regular;
  background-color: #02BA44;
  background-repeat: no-repeat;
  padding-top: 90px;
  background-position: top center;
  padding-bottom: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media(max-width: 768px){
    padding: 50px 20px;
    background-image: url('@/assets/svg/pattern@mobile.svg');
  }

  a {
    margin-top: 40px;
  }

  h3 {
    color: $white;
    text-align: center;
    font-family: $regular;
    font-size: 50px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 1px;
    margin-bottom: 0;

    br {
      display: none;

      @media(max-width: 576px){
        display: block;
      }
    }

    @media(max-width: 768px){
      font-size: 32px;
      letter-spacing: 0.64px;
    }
  }

  .logo {
    width: 100%;
    max-width: 200px;

    @media(max-width: 576px){
      max-width: 130px;
    }
  }

  .content {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    @media(max-width: 768px){
      position: relative;
      width: fit-content;
    }
  }
}

.disabled {
  pointer-events: none;
  filter: grayscale(100%);
}
</style>
