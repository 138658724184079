<template>
  <div class="pagination-wrapper">
    <!-- <div :class="['nav', { 'disabled' : activePage == 1 }]"
         @click="pageChange(activePage-1)"
         alt="Предыдущая страница">
      sdfdf
    </div> -->

    <div class="numbers">
      <div
          v-for="item in pageRange"
          :key="'pagination_'+ item.to"
          :class="['item', { 'active': activePage === item.title }]"
          @click="pageChange(item.to)"
      >
          {{ item.title }}
      </div>
    </div>

    <!-- <div @click="pageChange(activePage+1)"
         :class="['nav', { 'disabled' : activePage == pages.length }]"
         alt="Следующая страница">
      sdfsdf
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'Pagination',

  props: {
    pages: {
      type: Array,
      required: true
    },

    delta: {
      type: Number,
      default: 2
    }
  },

  data: () => ({
    activePage: 1,
  }),

  computed: {
    pageRange () {
      const last = this.pages[this.pages.length - 1]
      const left = this.activePage - this.delta
      const right = this.activePage + this.delta + 1
      const range = []
      const pages = []
      let l
        for (var i = 1; i <= last; i++) {
          if (i === 1 || i === last || (i >= left && i < right)) {
            range.push(i);
        }
      }

      range.forEach(function (i) {
        if (l) {
          if (i - l === 2) {
            pages.push({ title: (l + 1), to: (l + 1) });
          } else if (i - l !== 1) {
            if(i === last) {
              pages.push({ title: '...', to: parseInt((last + right) / 2)})
            }
              else {
                pages.push({ title: '...', to: parseInt((left) / 2)})
              }
            }
          }
        pages.push({ title: i, to: i })
        l = i
      })
      return pages
    }
  },

  methods: {
    pageChange(pageNumber) {
      this.activePage = pageNumber
      this.$emit('page-change', pageNumber)
    }
  }
}
</script>

<style scoped lang="scss">
.pagination-wrapper {
  display: flex;
  margin-top: 30px;
  align-items: center;
  justify-content: center;

  .numbers {
    display: flex;
    font-weight: 400;
    font-size: 18px;
    align-items: center;
    color: $black;

    div {
      cursor: pointer;
      margin: 0 15px;
      transition: .5s all;

      &:hover {
        opacity: 0.7;
        color: $black;
      }
    }
  }

  img {
    margin: 0 15px;
    width: 33px;
    height: 29px;
    cursor: pointer;
    transition: .6s all;

    &:hover {
      opacity: .7;
    }
  }
}

.active {
  color: #02BA44;
}

.disabled {
  pointer-events: none;
  background-color: #02BA44 !important;
}

.nav {
  background-color: #02BA44;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  width: 44px;
  border-radius: 50%;
}
</style>
