<template>
  <div class="office-wrapper">
    <div class="section-title"
         v-if="user">
      Личный кабинет
    </div>

    <div class="office-content">
      <!-- <h3 v-if="!user"
          class="mt-5">Авторизуйтесь, чтобы получить доступ к личному кабинету!</h3> -->

      <div class=""
           v-if="user">
        <div class="flex-wrapper">
          <div class="info">
            <div>
              <span class="bold">{{ user.surname }}</span>
            </div>

            <div>
              <span>{{ user.name }}</span>
            </div>

            <div class="d-flex contacts mt-3">
              <div class="mr-4">
                <img src="@/assets/svg/phone.svg" class="mr-2" />

                {{ user.phone }}
              </div>

              <div>
                <img src="@/assets/svg/email.svg" class="mr-2" />

                {{ user.email }}
              </div>
            </div>

            <div class="d-flex mt-3">
              <div style="margin-right: 90px;"
                   class="logout"
                   @click="logout">
                <img src="@/assets/svg/quit.svg" class="mr-2" />

                Выйти
              </div>

              <div v-b-modal.password-modal
                   class="option">
                Изменить пароль
              </div>
            </div>
          </div>

          <div class="card-wrapper">
            <img src='@/assets/card.png' class="card-img" />

            <div class="card-info">
              <div class="card-number">{{ user.card_number }}</div>

              <div v-b-modal.card-modal
                   class="option">
                Редактировать
              </div>

              <div class="card-text">
                На эту карту придут <br> выигранные вами баллы
              </div>
            </div>
          </div>
        </div>

        <div class="flex-wrapper">
          <div class="receipts-wrapper">
            <div class="d-flex justify-content-between align-items-center">
              <p class="title">Мои чеки</p>

              <button class="button button-red" v-b-modal.info-modal>
                Зарегистрировать чек
              </button>
            </div>

            <div class="table">
              <div class="table-header">
                <div>Дата</div>

                <div>Сумма в чеке</div>

                <div>Статус</div>
              </div>

              <div class="table-row"
                   v-if="receipts"
                   v-for="receipt in receipts">
                <div>{{ receipt.created_at.slice(0, 10) }}</div>

                <div>{{ receipt.price }}</div>

                <div>{{ receipt.status.i18n }}</div>
              </div>
            </div>
          </div>

          <div class="prizes-wrapper">
            <div>
              <p class="title">Мои призы</p>

              <p class="mt-4 subprizes" v-if="prizes.length < 1">
                Регистрируйте чеки, чтобы <br>
                участвовать в розыгрыше призов!
              </p>

              <div class="prize" v-for="prize in prizes">
                <img src="@/assets/prize-1.png"
                     v-if="(prize.prize.slug.includes('bonus_100')
                            || prize.prize.slug.includes('bonus_300')
                            || prize.prize.slug.includes('bonus_500')
                            || prize.prize.slug.includes('certificate_300')
                            || prize.prize.slug.includes('certificate_500')
                            || prize.prize.slug.includes('certificate_1000')
                            || prize.prize.slug.includes('certificate_2000')
                            || prize.prize.slug.includes('certificate_3000'))"
                />

                <img src="@/assets/sweatshirt.png"
                     v-if="(prize.prize.slug.includes('sweatshirt'))"
                />

                <img src="@/assets/headphones.png"
                     v-if="(prize.prize.slug.includes('wireless_headphones'))"
                />

                <img src="@/assets/double-boiler.png"
                     v-if="(prize.prize.slug.includes('double_boiler'))"
                />

                <img src="@/assets/bottle.png"
                     v-if="(prize.prize.slug.includes('bottle_for_water'))"
                />

                <img src="@/assets/mat.png"
                     v-if="(prize.prize.slug.includes('t_shirt'))"
                />

                <img src="@/assets/bag.png"
                     v-if="(prize.prize.slug.includes('sports_bag'))"
                />

                <img src="@/assets/wireless-speaker.png"
                     v-if="(prize.prize.slug.includes('wireless_speaker'))"
                />

                <img src="@/assets/blender.png"
                     v-if="(prize.prize.slug.includes('blender'))"
                />

                <img src="@/assets/juicer.png"
                     v-if="(prize.prize.slug.includes('juicer'))"
                />

                <img src="@/assets/main.png"
                     v-if="(prize.prize.slug.includes('main'))"
                />

                <!-- <img src="@/assets/prize.png"
                     v-else
                /> -->

                <div>
                  <!-- <p class="date">
                    {{ prize.period.winner_at.slice(8, 10) }}.{{ prize.period.winner_at.slice(5, 7) }}.23
                  </p> -->

                  <p class="date">
                    {{ prize.period.winner_at }}
                  </p>

                  <p class="name">{{ prize.prize.name }}</p>

                  <button class="button button-red" @click="openDelivery(prize.id)"
                          v-if="prize.has_delivery">
                    Получить
                  </button>

                  <button class="button button-red" @click="openVerification(prize.id)"
                          v-if="prize.has_passport">
                    Получить
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import QrScanner from 'qr-scanner'
import vueDropzone from "vue2-dropzone"
import { validationMixin } from "vuelidate"
import OfficeTable from '@/components/OfficeTable.vue'
import { mapState, mapGetters, mapMutations } from "vuex"
import PasswordChange from '@/components/PasswordChange.vue'
import { required, requiredIf, minLength, maxLength, or, helpers } from "vuelidate/lib/validators"


const number = helpers.regex('number', /^[:]*[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/)

export default {
  name: 'Office',

  components: {
    vueDropzone,
    OfficeTable,
    PasswordChange,
  },

  data(){
    return{
    }
  },

  mounted(){
    axios.defaults.headers.authorization = "Bearer " + this.token

    setTimeout(() => {
      if (this.passport){
        this.$bvModal.show('verification-modal')
      }

      if (this.delivery){
        this.$bvModal.show('delivery-modal')
      }
    }, 1200)

    setTimeout(() => {
      if (!this.user){
        this.$router.push('/')
      }
    }, 2000)
  },

  computed: {
    ...mapGetters({
      user: 'user',
      token: 'token',
      prizes: 'prizes',
      passport: 'passport',
      delivery: 'delivery',
      receipts: 'receipts'
    }),
  },

  methods: {
    ...mapMutations({
      saveUser: 'saveUser',
      saveToken: 'saveToken',
      getErrorText: 'getErrorText',
      savePrizeUuid: 'savePrizeUuid',
    }),

    openDelivery(uuid){
      this.savePrizeUuid(uuid)

      this.$bvModal.show('delivery-modal')
    },

    openVerification(uuid){
      this.savePrizeUuid(uuid)

      this.$bvModal.show('verification-modal')
    },

    logout(){
      axios.post('auth/logout', {
      }, {
        headers: {
          'Accept': "application/json",
          'Content-Type': "application/json",
          'access-control-allow-origin': "*",
          'Authorization': "Bearer " + localStorage.getItem('token')
        }
      }).then((response) => {
        this.saveUser(null)
        this.saveToken(null)
        this.$router.push('/')

        localStorage.removeItem('token')
      }).catch((error) => {
          console.log(error)
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.office-wrapper {
  font-family: $regular !important;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: $white;
  padding-top: 40px;
  min-height: calc(100vh - 336px);
  flex-direction: column;
  align-items: center;

  @media(max-width: 1500px){
    background-position: top;
    padding-left: 15px;
    padding-right: 15px;
  }

  @media(max-width: 1200px){
    flex-direction: column;
  }

  .borders {
    border-radius: 25px !important;
    margin-top: 30px;
    padding-top: 60px !important;
  }

  .office-content {
    width: 100%;
    min-height: 600px;
    max-width: 1200px;

    @media(max-width: 1600px){
      max-width: 1000px;
    }

    @media(max-width: 1200px){
      min-height: auto;
      border-radius: 0 0 25px 25px;
    }

    @media(max-width: 768px){
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

.flex-wrapper {
  display: flex;
  justify-content: space-between;

  @media(max-width: 760px){
    flex-direction: column;
  }
}

.prize {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 50px;
  line-height: 100%;
  font-family: $regular;

  img {
    max-width: 120px;
    margin-right: 25px;
  }

  .date {
    color: white;
    font-size: 12px;
    margin-bottom: 5px;
  }

  .name {
    font-weight: 900;
    font-size: 17px;
    color: #392A25;
  }

  .button {
    max-width: 180px;
    font-weight: 900;
    width: 100%;
  }
}

.info {
  font-family: $regular;
  margin-bottom: 50px;

  @media(max-width: 760px){
    margin-bottom: 30px;
  }

  div {
    display: flex;
    align-items: center;

    @media(max-width: 760px){
      text-align: center;
      justify-content: center;
      font-size: 12px;
    }

    span {
      @media(max-width: 576px){
        font-size: 16px;
        text-align: center;
      }
    }

    .bold {
      font-weight: 900;
    }
  }
}

.option {
  text-decoration: underline;
  cursor: pointer;
}

.logout {
  opacity: .8;
  cursor: pointer;
}

.section-title {
  color: #392A25;

  @media(max-width: 768px){
    margin-bottom: 30px;
  }
}

.contacts {
  color: #02BA44;
}

.card-wrapper {
  display: flex;
  align-items: center;

  @media(max-width: 760px){
    justify-content: center;
    margin-bottom: 20px;
  }

  .card-img {
    max-width: 160px;

    @media(max-width: 760px){
      max-width: 120px;
    }
  }

  .card-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-left: 20px;

    @media(max-width: 760px){
      margin-left: 10px;
    }
  }

  .card-number {
    background-color: #02BA44;
    border-radius: 45px;
    color: $white;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: $regular;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    padding: 11px 30px;
    line-height: 16px;

    @media(max-width: 760px){
      line-height: 100%;
      font-size: 12px;
      padding: 11px 20px;
    }
  }

  .card-text {
    color: #392A25;
    text-align: center;
    font-family: $regular;
    font-size: 14px;
    font-style: normal;
    margin-top: 8px;
    font-weight: 400;
    line-height: 120%;

    @media(max-width: 760px){
      line-height: 110%;
      font-size: 12px;
      margin-top: 10px;
    }
  }
}

.receipts-wrapper {
  background-color: #F0F0F0;
  margin-right: 20px;

  @media(max-width: 760px){
    margin-right: 0;
    margin-bottom: 20px;
  }

  .title {
    color: #392A25;
  }
}

.prizes-wrapper {
  margin-left: 20px;
  background-color: #02BA44;

  @media(max-width: 760px){
    margin-left: 0;
  }

  .title {
    color: $white;
    margin-bottom: 50px !important;
  }
}

.receipts-wrapper,
.prizes-wrapper {
  padding: 35px 30px;
  border-radius: 50px;
  width: 100%;
  color: #392A25;
  font-family: $regular;

  @media(max-width: 760px){
    padding: 25px 20px;
  }

  .title {
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: $regular;
    font-size: 24px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    margin-bottom: 0;

    @media(max-width: 760px){
      font-size: 18px;
    }
  }
}

.button {
  font-weight: 400;
  font-size: 16px;

  @media(max-width: 760px){
    font-size: 13px;
    width: fit-content;
  }
}

.table-header {
  margin-top: 25px;
  opacity: 0.6;
  font-size: 16px;

  @media(max-width: 1600px){
    font-size: 12px;
  }
}

.table-row,
.table-header {
  display: flex;
  justify-content: space-between;

  div {
    width: 33.3333%;
  }
}

.table-row {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 16px;

  @media(max-width: 1600px){
    font-size: 12px;
  }
}

.table {
  max-height: 510px;
  overflow-y: scroll;
}

.subprizes {
  color: white;
  font-weight: 500;
  text-align: center;
  font-family: $regular;
}
</style>
