<template>
  <header class="">
    <div class="header-wrapper">
      <div class="d-flex align-items-center justify-content-between w-100">
        <div class="d-flex align-items-center">
          <img src="@/assets/svg/actibio.svg" class="logo" />

          <img src="@/assets/svg/5ka.svg" class="logo-5" />
        </div>

        <div class="d-flex align-items-center desktop">
          <div class="d-flex align-items-center">
            <router-link :to="{ path: '/#promo' }"
                         v-scroll-to="'#promo'"
                         class="header-link">
              <img src="@/assets/svg/main.svg" />
            </router-link>

            <a v-if="rules.length > 0" class="header-link" :href="rules[0].url" target="_blank">
              <img src="@/assets/svg/rules.svg" />
            </a>

            <router-link :to="{ path: '/#prizes' }"
                         v-scroll-to="'#prizes'"
                         class="header-link">
              <img src="@/assets/svg/prizes.svg" />
            </router-link>

            <router-link :to="{ path: '/winners' }"
                         v-scroll-to="'#winners'"
                         class="header-link">
              <img src="@/assets/svg/winners.svg" />
            </router-link>

            <router-link :to="{ path: '/#faq' }"
                         v-scroll-to="'#faq'"
                         class="header-link">
              <img src="@/assets/svg/faq.svg" />
            </router-link>
          </div>
        </div>

        <div class="d-flex align-items-center">
          <button class="button button-red mr-2"
                  @click="openModal">Загрузить чек</button>

          <img class="clickable"
               src="@/assets/svg/logout-icon.svg"
               v-b-modal.login-modal
               v-if="!token"></img>

          <router-link to="/office"
                       class="clickable"
                       style="text-decoration: none !important; width: 100%;">
            <img class=""
                 src="@/assets/svg/login-icon.svg"
                 v-if="token"></img>
          </router-link>
        </div>
      </div>
    </div>

    <div class="header-wrapper bottom">
      <div class="d-flex align-items-center justify-content-center w-100">
        <div class="d-flex align-items-center justify-content-between w-100">
          <div class="d-flex align-items-center justify-content-between w-100">
            <router-link :to="{ path: '/#promo' }"
                         v-scroll-to="'#promo'"
                         class="header-link">
              <img src="@/assets/svg/main.svg" />
            </router-link>

            <a v-if="rules.length > 0" class="header-link" :href="rules[0].url" target="_blank">
              <img src="@/assets/svg/rules.svg" />
            </a>

            <router-link :to="{ path: '/#prizes' }"
                         v-scroll-to="'#prizes'"
                         class="header-link">
              <img src="@/assets/svg/prizes.svg" />
            </router-link>

            <router-link :to="{ path: '/winners' }"
                         v-scroll-to="'#winners'"
                         class="header-link">
              <img src="@/assets/svg/winners.svg" />
            </router-link>

            <router-link :to="{ path: '/#faq' }"
                         v-scroll-to="'#faq'"
                         class="header-link">
              <img src="@/assets/svg/faq.svg" />
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import axios from 'axios'
import { mapGetters, mapMutations } from "vuex"

export default {
  name: 'HeaderRegion',

  data: () => ({
    mobileMenuOpened: false,
  }),

  computed: {
    ...mapGetters({
      token: 'token',
      rules: 'rules'
    }),
  },

  methods: {
    ...mapMutations({
      saveUser: 'saveUser',
      saveToken: 'saveToken',
      getErrorText: 'getErrorText'
    }),

    openVoucherModal(){
      this.$bvModal.show('voucher-modal')
    },

    openModal(){
      this.$bvModal.show('info-modal')
    },

    openEnterModal(){
      this.$bvModal.show('enter-modal')
    },

    logout(){
      this.mobileMenuOpened = false

      this.saveUser(null)
      this.saveToken(null)
      this.$router.push('/')

      localStorage.removeItem('token')
      sessionStorage.removeItem('token')
    },
  }
}
</script>

<style scoped lang="scss">
header {
  height: 80px;

  @media(max-width: 876px){
    height: 60px;
  }
}

.header-wrapper {
  height: 80px;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $light-green;
  padding: 0 180px;
  position: fixed;
  width: 100%;
  padding-right: 80px;

  @media(max-width: 1600px){
    padding: 0 50px;
  }

  @media(max-width: 1200px){
    padding: 0 20px;
  }

  @media(max-width: 876px){
    padding: 0 17px;
    height: 60px;
  }

  @media(max-width: 380px){
    padding: 0 8px;
  }
}

.header-link {
  cursor: pointer;
  opacity: 0.7;
  color: $darker-grey;
  font-weight: 900 !important;
  text-decoration: none;
  font-size: 16px;
  line-height: 100%;
  font-family: $regular;
  transition: .4s all ease;
  margin-right: 40px;
  white-space: nowrap;

  &:hover {
    opacity: .85 !important;
  }

  &:last-child {
    margin-right: 0px;

    @media(max-width: 1200px){
      margin-right: 30px;
    }

    @media(max-width: 576px){
      margin-right: 0;
    }
  }

  @media(max-width: 1400px){
    margin-right: 12px;
    font-size: 14px;
  }

  img {
    max-width: 50px;

    @media(max-width: 350px){
      max-width: 30px;
    }
  }
}

.router-link-exact-active {
  font-weight: 700;
  opacity: 1 !important;
  color: $red !important;
}

.mobile-icon {
  cursor: pointer;
  display: none;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  transition: 1s all;

  @media(max-width: 999px){
    display: flex;
  }

  &:hover {
    opacity: 0.9;
  }

  img {
    margin: 0;
    transition: 1s all;
    width: 39px;
    height: 30px;

    @media(max-width: 350px){
      width: 28px;
      height: 28px;
    }
  }
}

.hidden {
  z-index: -1;
}

.mobile-menu {
  background-color: $light-green;
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 25px;
  position: absolute;
  width: 100vw;
  margin-left: -3000px;
  transition: .8s all ease;
  z-index: 999;
  margin-top: 76px;

  @media(max-width: 999px){
    display: flex;
  }

  .header-link {
    opacity: 0.7;
    font-size: 16px;
    display: block !important;
    margin-top: 32px;
    font-weight: 900 !important;
    margin-left: 0;
    margin-right: 0 !important;

    &:first-child {
      margin-top: 0;
    }
  }

  &.active {
    margin-left: 0px;
  }
}

.desktop {
  @media(max-width: 999px){
    display: none !important;
  }
}

.mobile {
  width: 100%;
  display: none !important;

  @media(max-width: 999px){
    display: flex !important;
  }
}

.button-red {
  @media(max-width: 999px){
    max-width: none;
  }
}

.logo {
  margin-right: 30px;

  @media(max-width: 876px){
    max-height: 25px;
    margin-right: 12px;
  }

  @media(max-width: 380px){
    max-height: 20px;
    margin-right: 7px;
  }
}

.logo-5 {
  @media(max-width: 876px){
    max-height: 35px;
  }

  @media(max-width: 380px){
    max-height: 30px;
  }
}

.bottom {
  padding: 0 29px;
  position: fixed;
  bottom: 0;
  display: none;

  @media(max-width: 876px){
    display: flex;
  }
}
</style>
