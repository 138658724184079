<template>
  <section class="wrapper" id="faq">
    <div class="faq-content">
      <div class="accordion" role="tablist">
        <b-card no-body class="mb-1"
                v-for="(question, index) in questions">
          <b-card-header header-tag="header" role="tab">
            <b-button block v-b-toggle="'accordion-' + index" variant="info">
              <span>{{ question.question }}</span>
            </b-button>
          </b-card-header>
          <b-collapse :id="'accordion-' + index" visible accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card-text v-html="question.answer">
              </b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Faq',

  data: () => ({
    questions: []
  }),

  mounted(){
    this.getQuestions()
  },

  computed: {
  },

  methods: {
    getQuestions(){
      axios.get('faq', {
        headers: {
          'Accept': "application/json",
          'Content-Type': "application/json",
          'access-control-allow-origin': "*"
        }
      }).then((response) => {
        this.questions = response.data.data
      })
    }
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  padding: 0;
  font-family: $regular;
}

.section-title {
  text-align: center;
  font-weight: 900;
}

.link {
  color: $green;
  text-decoration: underline;

  &:hover {
    opacity: .9;
  }
}

.button-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

.button {
  margin-top: 50px;
  max-width: 260px;
  font-size: 16px;

  @media(max-width: 768px){
    margin-top: 30px;
    max-width: none;
  }
}
</style>
