<template>
  <div id="app">
    <HeaderRegion id="promo" />

    <router-view/>

    <FooterRegion />

    <Info />

    <Plug />

    <Enter />

    <Rules />

    <Login />

    <Reset />

    <Error />

    <Thanks />

    <Success />

    <Delivery />

    <CardChange />

    <EmailLogin />

    <Registration />

    <CashVoucher />

    <PasswordSent />

    <Verification />

    <PasswordChange />
  </div>
</template>

<script>
import axios from 'axios'
import Info from '@/components/modals/Info'
import Plug from '@/components/modals/Plug'
import Login from '@/components/modals/Login'
import Enter from '@/components/modals/Enter'
import Reset from '@/components/modals/Reset'
import Rules from '@/components/modals/Rules'
import Error from '@/components/modals/Error'
import Thanks from '@/components/modals/Thanks'
import Success from '@/components/modals/Success'
import Feedback from '@/components/modals/Feedback'
import Delivery from '@/components/modals/Delivery'
import HeaderRegion from '@/components/HeaderRegion'
import FooterRegion from '@/components/FooterRegion'
import CardChange from '@/components/modals/CardChange'
import EmailLogin from '@/components/modals/EmailLogin'
import CashVoucher from '@/components/modals/CashVoucher'
import { mapState, mapGetters, mapMutations } from "vuex"
import Registration from '@/components/modals/Registration'
import PasswordSent from '@/components/modals/PasswordSent'
import Verification from '@/components/modals/Verification'
import PasswordChange from '@/components/modals/PasswordChange'

export default {
  name: 'App',

  components: {
    Info,
    Plug,
    Enter,
    Login,
    Reset,
    Rules,
    Error,
    Thanks,
    Success,
    Feedback,
    Delivery,
    CardChange,
    EmailLogin,
    CashVoucher,
    HeaderRegion,
    FooterRegion,
    Registration,
    PasswordSent,
    Verification,
    PasswordChange,
  },

  data() {
    return {
      noBtn: '',
      authText: '',
      authPhone: '',
      thanksText: '',
      errorText: '',
    }
  },

  methods: {
    ...mapMutations({
      saveUser: 'saveUser'
    }),

    getThanksText(value) {
      this.thanksText = value
   },

   getErrorText(value) {
     this.errorText = value
    },

   getAuthText(value) {
     this.authText = value
    },

   getAuthPhone(value) {
     this.authPhone = value
    },
  },

  computed: {
    ...mapGetters({
      token: 'token',
    }),
  },

  mounted() {
    this.$bvModal.show('info-modal')
    this.$store.dispatch('getDocuments')

    if (this.token) {
      this.$store.dispatch('getUser')
      this.$store.dispatch('getReceipts')
      this.$store.dispatch('getPrizes')
      axios.defaults.headers.authorization = "Bearer " + this.token
    }
  },
}
</script>

<style lang="scss">

</style>
