<template>
  <section class="promo-wrapper" id="prizes">
    <h1 class="section-title">Призы</h1>

    <div class="content">
      <div class="line">
        <div class="prize">
          <div class="prize-title">
            Ежедневные
          </div>

          <img src="@/assets/prize-1.png" class="side-prize" />

          <div class="text">
            Баллы на карту
          </div>
        </div>

        <div class="prize">
          <div class="prize-title">
            Еженедельные
          </div>

          <img src="@/assets/prize-2.png" class="main-prize" />

          <div class="text">
            Худи, наушники, мультиварки, <br> спортивные бутылки
          </div>
        </div>

        <div class="prize">
          <div class="prize-title">
            Главный приз
          </div>

          <img src="@/assets/prize-3.png" class="side-prize" />

          <div class="text">
            Смартфон
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapMutations } from "vuex"

export default {
  name: 'Prizes',

  data: () => ({
    step: 1,
  }),

  computed: {
    ...mapGetters({
      token: 'token',
    }),
  },

  methods: {
    next(){
      this.step++
    },

    prev(){
      this.step--
    }
  }
}
</script>

<style scoped lang="scss">
.promo-wrapper {
  background-image: url('@/assets/svg/pattern@big.svg');
  background-color: $light-green;
  background-size: cover;
  font-family: $regular;
  background-position: top center;
  background-repeat: no-repeat;
  padding-top: 130px;
  padding-bottom: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media(max-width: 1200px){
    padding-top: 80px;
    padding-bottom: 80px;
    background-image: url('@/assets/svg/pattern@mobile.svg');
    background-size: cover;
  }

  @media(max-width: 768px){
    background-size: 100%;
  }

  @media(max-width: 576px){
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .content {
    width: 100%;
    display: flex;
    align-items: start;
    flex-direction: column;
    justify-content: space-between;

    @media(max-width: 999px){
      align-items: center;
    }

    .subtitle {
      text-align: center;
      width: 100%;
      margin-top: 50px;
      font-weight: 500;
      font-family: $regular;
      font-size: 38px;
      line-height: 45px;
      margin-bottom: 30px;
      color: $blue;
    }
  }
}

.button {
  max-width: 342px;
  margin-top: 80px;

  @media(max-width: 999px){
    margin-top: 20px;
    font-size: 14px;
  }
}

.line {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  margin-top: 50px;
  justify-content: center;

  @media(max-width: 1200px){
    flex-direction: column;
  }

  .prize {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    height: 100%;
    margin: 0 70px;

    @media(max-width: 1200px){
      margin: 17px 0;
    }

    .side-prize {
      max-width: 240px;

      @media(max-width: 768px){
        max-width: 180px;
      }
    }

    .main-prize {
      max-width: 440px;

      @media(max-width: 768px){
        max-width: 300px;
      }
    }

    .prize-title {
      color: $white;
      text-align: center;
      font-family: $regular;
      font-size: 36px;
      font-style: normal;
      font-weight: 900;
      margin-bottom: 20px;
      line-height: 130%;

      @media(max-width: 999px){
        font-size: 24px;
      }
    }

    .text {
      color: $white;
      text-align: center;
      font-family: $regular;
      margin-top: 15px;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      height: 65px;
      line-height: 130%;

      @media(max-width: 999px){
        font-size: 16px;
      }
    }
  }
}

.desktop {
  @media(max-width: 999px){
    display: none !important;
  }
}

.mobile {
  display: none !important;

  @media(max-width: 999px){
    display: flex !important;
  }
}

.disabled {
  pointer-events: none;
  filter: grayscale(100%);
}
</style>
