<template>
    <b-modal
      id="delivery-modal"
      ref="modal"
      title=""
      hide-footer
      hide-header
      centered
      no-close-on-backdrop
      class="ver-modal"
    >
      <div class="header">
        <span class="close-icon"
              @click="closeModal">
          <img src="@/assets/svg/close.svg"
               aria-label="close">
        </span>

        <h3>
          Поздравляем!
        </h3>

        <div class="subtext">
          Для получения приза, пожалуйста, <br> заполните информацию ниже:
        </div>
      </div>

      <form ref="form"
            class="modal-form"
            @submit.stop.prevent="submit">
        <b-form-group
          id="name-input"
          label-for="name-input"
          :invalid-feedback="invalidName"
        >
          <b-form-input
            id="name-input"
            placeholder="ФИО"
            required
            type="text"
            pattern="[A-Za-z]+"
            :class="{ 'is-invalid' : isInvalidName }"
            v-model="$v.item.fio.$model"
            :state="validateState($v.item.fio)"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label-for="phone-input"
          v-mask="'+7 (###) ###-##-##'"
          :invalid-feedback="invalidPhone"
        >
          <b-form-input
            @click="putPlaceholder"
            id="phone-input"
            placeholder="Контактный номер телефона*"
            v-model="$v.item.phone.$model"
            maxlength="18"
            :state="validateState($v.item.phone)"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label-for="address-input"
          :invalid-feedback="invalidAddress"
        >
          <b-form-input
            id="address-input"
            placeholder="Адрес доставки"
            v-model="$v.item.address.$model"
            :state="validateState($v.item.address)"
            required
          ></b-form-input>
        </b-form-group>
    </form>

    <b-button class="button button-red"
              type="submit"
              :disabled="disabledBtn"
              @click="submit">Отправить</b-button>
  </b-modal>
</template>

<script>
import axios from 'axios'
import { validationMixin } from "vuelidate"
import { mapGetters, mapMutations } from "vuex"
import { required, requiredIf, minLength, maxLength, or, helpers } from "vuelidate/lib/validators"

const text = helpers.regex('text', /^[?!,.а-яА-ЯёЁ\-\s]+$/)
const email = helpers.regex('email', /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)
const number = helpers.regex('number', /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/)
const token = localStorage.getItem('token')

  export default {
    mixins: [validationMixin],

    validations: {
      item: {
        fio: {
          required,
          minLength: minLength(2),
          text,
        },
        phone: {
          required
        },
        address: {
          required
        }
      }
    },

    data() {
      return {
        item: {
          fio: '',
          phone: '',
          address: '',
        },
        isInvalidName: false,
        invalidPhone: 'Некорректное поле',
        invalidName: 'Некорректное поле',
        invalidAddress: 'Некорректное поле'
      }
    },

    computed: {
      ...mapGetters({
        token: 'token',
        prizeUuid: 'prizeUuid'
      }),

      disabledBtn(){
        if (this.$v.item.$anyError || this.item.fio < 1 || this.item.phone < 1 || this.item.address < 1){
          return true
        }
      }
    },

    mounted(){
    },

    methods: {
      ...mapMutations({
        saveUser: 'saveUser',
        saveToken: 'saveToken',
        getSuccessText: 'getSuccessText',
      }),

      putPlaceholder(){
        if (this.item.phone.length < 2){
          this.item.phone = '+7'
        }
      },

      resetModal() {
        this.$bvModal.hide('delivery-modal')

        this.$v.$reset()

        this.item.fio = ''
        this.item.phone = ''
        this.item.address = ''
      },

      closeModal(){
        this.$v.$reset()

        this.item.fio = ''
        this.item.phone = ''
        this.item.address = ''


        this.$bvModal.hide('delivery-modal')
      },

      validateState(item) {
        const { $dirty, $error } = item
        return $dirty ? !$error : null
      },

      submit() {
        this.$v.item.$touch()

        if (this.$v.item.$anyError)
          return false

          const formData = new FormData()
          formData.append('fio', this.item.fio)
          formData.append('phone', this.item.phone)
          formData.append('delivery_address', this.item.address)

          axios.post('forms/delivery/' + this.prizeUuid, formData, {
          }, {
            headers: {
              'Authorization': "Bearer " + localStorage.getItem('token'),
              'Accept': "application/json",
              'Content-Type': "application/json",
              'access-control-allow-origin': "*",
            }
          }).then((response) => {
            this.resetModal()

            this.$store.dispatch('getPrizes')

            this.getSuccessText(response.data.message)
            this.$bvModal.show('success-modal')
          }).catch((error) => {
            console.log(error)
        })
      },
    }
  }
</script>

<style scoped lang="scss">
.button {
  margin-top: 40px;
  width: 100%;
  max-width: 125px;
  height: 43px !important;
  margin-bottom: 15px;
  border: 1px solid $black !important;

  &:hover {
    opacity: 0.8 !important;
  }
}

.form-control {
  border-radius: 30px;
  border: 1px solid #ABAB9A;
  background: #F7F7F6 !important;
  transition: .6s all;
  height: 57px;
  display: flex;
  color: $black;
  padding-left: 25px !important;
  align-items: center;
  box-shadow: 0px 7px 20px 2px rgba(103, 103, 103, 0.1) !important;
  margin-top: 4px;

  &::placeholder {
    color: #626262
  }

  &:focus {
    box-shadow: none;
    border: 1px solid $black;
    box-shadow: 0px 7px 20px 2px rgba(103, 103, 103, 0.1) !important;
  }
}

.ver-modal {
  background: #F7F7F6 !important;
}

.subtext {
  color: white;
  text-align: center;
  font-family: $regular;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin-bottom: 18px;
}

.modal-form {
  width: 100%;
  max-width: 360px;
}

.modal-title {
  padding: 7px 0;
  width: 114%;
  margin-left: -30px;
  margin-top: -35px;

  @media(max-width: 576px){
    width: 100%;
    margin-left: 0;
    margin-top: 0;
    font-size: 24px;
    margin-top: 50px;
    margin-bottom: 20px;
  }
}

a {
  color: $red;
}

.button-red {
  max-width: 260px;
  height: 62px;
  border: none !important;
  box-shadow: none !important;
  font-size: 24px !important;
}

label {
  width: 100%;
  text-decoration: underline;
  margin-bottom: 7px;
  cursor: pointer;
}

.fake-upload {
  color: $black;
  font-size: 18px;
  font-weight: 400;

  &:hover {
    opacity: 0.9;
  }
}

input[type='file'] {
  position: absolute;
  opacity: 0;
  height: 0;
}

.uploaded-img {
  background-color: $white;
  padding: 10px 15px;
  border-radius: 15px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.delete {
  width: 25px;
  height: 25px;
  background-color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  &:hover {
    opacity: 0.8;
  }

  img {
    max-width: 17px;
    max-height: 17px;
  }
}

.error {
  color: $red;
  font-size: 12px;
  margin-top: -20px;
}

.fake-button {
  font-size: 14px;
  font-family: $regular;
  font-weight: 400;
  color: black;
}

.docs-left {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  margin-top: 15px;
  margin-left: -307px;
  max-height: 650px;

  @media(max-width: 768px){
    display: none;
  }
}

.docs-right {
  position: absolute;
  top: 0;
  max-height: 700px;
  z-index: -1;
  right: 0;
  margin-top: -60px;
  margin-right: -394.5px;

  @media(max-width: 768px){
    display: none;
  }
}
</style>
