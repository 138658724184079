<template>
  <div>
    <b-modal
      id="rules-modal"
      ref="modal"
      title=""
      hide-footer
      hide-header
      centered
    >
      <div class="header">
        <span class="close-icon"
              @click="$bvModal.hide('rules-modal')">
          <img src="@/assets/svg/close.svg"
               aria-label="close">
        </span>

        <h3>
          В разделе 11, 16 Правил Акции <br> произошли изменения.
        </h3>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex"

  export default {
    data() {
      return {
      }
    },

    computed: {
      ...mapGetters({
        errorText: 'errorText',
        token: 'token'
      }),
    },

    methods: {
      openVoucher(){
        this.$bvModal.hide('plug-modal')
        this.$bvModal.show('voucher-modal')
      }
    }
  }
</script>

<style scoped lang="scss">
.header {
  flex-direction: column;
  width: 100%;

  .close-icon {
    float: right;
    cursor: pointer;
    transition: .4s all;
    margin-right: 5px;

    &:hover {
      opacity: .85;
    }
  }
}

.content-modal {
  padding-top: 50px;
  text-align: center;
}
</style>
