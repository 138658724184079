<template>
  <section class="promo-wrapper" id="participate">
    <div class="content">
      <div class='step-wrapper'>
        <img src="@/assets/svg/step-1.svg"
             class="step-img desktop" />

        <img src="@/assets/svg/step-1@mobile.svg"
             class="step-img mobile" />

        <div class="text">
          Покупайте <br>
          <span class="highlighted">
            <router-link :to="{ path: '/#products' }"
                         class="highlighted"
                         v-scroll-to="'#products'">
              йогурты «АктиБио»
            </router-link>
          </span> <br>
          <span class="sub">с 18 сентября по 15 октября</span>
        </div>
      </div>

      <div class='step-wrapper'>
        <img src="@/assets/svg/step-2.svg"
             class="step-img" />

        <div class="text">
          Регистрируйте чеки
          <button class="button button-red" @click="openModal">
            Зарегистрировать чек
          </button>
        </div>
      </div>

      <div class='step-wrapper'>
        <img src="@/assets/svg/step-3.svg"
             class="step-img" />

        <div class="text">
          Получайте 100 баллов <br>
          на карту X5 Клуба <br>
          за первую покупку
        </div>
      </div>

      <div class='step-wrapper'>
        <img src="@/assets/svg/step-4.svg"
             class="step-img" />

        <div class="text">
          Выигрывайте призы <br>
          для новой себя!
        </div>
      </div>
    </div>

    <div v-if="rules.length > 0">
      <a :href="rules[0].url"
         class="link"
         target="_blank">
        Полные правила акции
      </a>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapMutations } from "vuex"

export default {
  name: 'Participate',

  data: () => ({
  }),

  computed: {
    ...mapGetters({
      token: 'token',
      rules: 'rules'
    }),
  },

  methods: {
    openModal(){
      this.$bvModal.show('info-modal')
    },
  }
}
</script>

<style scoped lang="scss">
.promo-wrapper {
  background-size: 100%;
  font-family: $regular;
  background-repeat: no-repeat;
  padding-top: 90px;
  padding-bottom: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media(max-width: 999px){
    padding-top: 80px;
  }

  @media(max-width: 576px){
    padding-top: 40px;
  }

  .content {
    width: 100%;
    display: flex;
    align-items: start;
    justify-content: space-between;

    @media(max-width: 768px){
      position: relative;
    }

    @media(max-width: 760px){
      flex-direction: column;
    }
  }
}

.button {
  margin-top: 25px;

  @media(max-width: 999px){
    font-size: 12px;
  }

  @media(max-width: 760px){
    margin-top: 10px;
    height: 33px;
  }
}

.step-wrapper {
  @media(max-width: 760px){
    display: flex;
    margin-top: 35px;
    width: 100%;

    &:first-child {
      margin-top: 0;
    }
  }

  .step-img {
    @media(max-width: 999px){
      max-width: 110px;
    }

    @media(max-width: 760px){
      margin-right: 40px;
    }
  }

  .text {
    font-weight: 500;
    margin-top: 25px;
    font-family: $regular;
    font-size: 24px;
    line-height: 120%;

    @media(max-width: 999px){
      font-size: 14px;
      margin-top: 13px;
    }

    @media(max-width: 760px){
      font-size: 18px;
    }

    .highlighted {
      color: $green;
      text-decoration: underline;
      line-height: 50px;

      @media(max-width: 999px){
        line-height: 35px;
      }
    }

    .sub {
      font-size: 18px;
      line-height: 25px;

      @media(max-width: 999px){
        line-height: 20px;
        font-size: 9px;
      }

      @media(max-width: 760px){
        font-size: 12px;
      }
    }
  }
}

.link {
  margin-top: 60px;
  color: #02A73D;
  text-align: center;
  font-family: $regular;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  cursor: pointer;
  text-decoration-line: underline;
}

.desktop {
  @media(max-width: 760px){
    display: none;
  }
}

.mobile {
  display: none;

  @media(max-width: 760px){
    display: block;
    max-width: 95px !important;
    margin-right: 55px !important;
  }
}
</style>
