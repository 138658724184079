<template>
  <div>
    <b-modal
      id="password-modal"
      ref="modal"
      title=""
      hide-footer
      hide-header
      centered
      no-close-on-backdrop
    >
      <div class="header">
        <span class="close-icon"
              @click="closeModal">
          <img src="@/assets/svg/close.svg"
               aria-label="close">
        </span>

        <h3>
          Изменение пароля
        </h3>
      </div>

      <div class="w-100 content-modal mt-4">
        <div class="w-100" id="password-change">
          <form ref="form"
                class="modal-form"
                @submit.stop.prevent="submit">
               <b-form-group
                 label-for="password-input"
                 invalid-feedback="Некорректный пароль"
               >
                 <b-form-input
                   type="password"
                   id="password-input"
                   placeholder="Введите новый пароль"
                   v-model="$v.item.password.$model"
                   maxlength="6"
                   :state="validateState($v.item.password)"
                   required
                 ></b-form-input>
               </b-form-group>

               <b-form-group
                 label-for="new-password-input"
                 invalid-feedback="Некорректный пароль"
               >
                 <b-form-input
                   type="password"
                   id="new-password-input"
                   placeholder="Повторите новый пароль"
                   v-model="$v.item.newPassword.$model"
                   maxlength="6"
                   :state="validateState($v.item.newPassword)"
                   required
                 ></b-form-input>
               </b-form-group>
          </form>
        </div>

        <button class="button button-red"
                type="submit"
                :class="{ 'disabled' : disabledBtn }"
                @click="submit">Изменить</button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
import VueRecaptcha from 'vue-recaptcha'
import { validationMixin } from "vuelidate"
import { mapGetters, mapMutations } from "vuex"
import { required, requiredIf, minLength, maxLength, or, helpers } from "vuelidate/lib/validators"

const email = helpers.regex('email', /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)

  export default {
    components: {
      VueRecaptcha
    },

    mixins: [validationMixin],

    validations: {
      item: {
        password: {
          required
        },
        newPassword: {
          required
        }
      }
    },

    data() {
      return {
        item: {
          password: '',
          newPassword: ''
        },
      }
    },

    computed: {
      ...mapGetters({
      }),

      disabledBtn(){
        if ((this.$v.item.$anyError) || (this.item.newPassword.length < 1) || (this.item.password.length < 1))
          return true
      }
    },

    methods: {
      ...mapMutations({
        getSuccessText: 'getSuccessText',
        getErrorText: 'getErrorText'
      }),

      closeModal(){
        this.$v.$reset()

        this.item.password = '',
        this.item.newPassword = '',

        this.$bvModal.hide('password-modal')
      },

      validateState(item) {
        const { $dirty, $error } = item
        return $dirty ? !$error : null
      },

      submit() {
        this.$v.item.$touch()

        if (this.$v.item.$anyError)
          return false

        axios.put('user/password', {
          password: this.item.password,
          password_confirmation: this.item.newPassword
        }).then((response) => {
          this.closeModal()

          this.getSuccessText(response.data.message)
          this.$bvModal.show('success-modal')
        }).catch((error) => {
          this.closeModal()

          this.getErrorText(error.response.data.message)
          this.$bvModal.show('error-modal')
        })
      },
    }
  }
</script>

<style scoped lang="scss">
.header {
  flex-direction: column;
  width: 100%;

  .close-icon {
    float: right;
    cursor: pointer;
    transition: .4s all;
    margin-right: 5px;

    &:hover {
      opacity: .85;
    }
  }
}

.subtext {
  font-family: $regular;
  color: $white;
  font-size: 15px;
  text-align: center;
  margin-top: -5px;
  margin-bottom: 10px;
}

.error {
  font-size: 8px;
  color: red;
  margin-top: -10px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button {
  margin-top: 15px;
  max-width: 260px;
  width: fit-content;
}

.form {
  max-width: 320px;
}
</style>
