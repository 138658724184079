<template>
  <section class="home">
    <div class="content-wrapper">
      <h1 class="section-title">
        Победители
      </h1>

      <div class="content-wrapper">
        <!-- <div v-if="!data"
             class="plug">
          Успейте зарегистрировать Чеки и
          принять участие в розыгрыше!
        </div> -->

        <div class="mobile">
          Введите номер телефона для поиска
        </div>

        <div class="search-bar">
          <div class="desktop">
            Введите номер телефона для поиска
          </div>

          <b-form-input id="search-bar"
                        type="search"
                        class="search"
                        placeholder="Телефон"
                        v-model="searchPhone"
                        invalid-feedback="Некорректная почта"
                        v-debounce:400ms="searchWinner"
          >
          </b-form-input>

          <button class="button button-green"
                  v-debounce:400ms="searchWinner">
            Найти
          </button>
        </div>

        <div class="table"
             v-if="data">
          <div>
            <div class="table-header">
              <div>Дата</div>

              <div>Имя</div>

              <div>Телефон</div>

              <div>Приз</div>
            </div>
          </div>

          <div v-if="noResults"
               class="plug mt-4">
            Нет результатов
          </div>

          <div class="table-line"
               v-if="winners"
               v-for="winner in winners">
            <div>{{ winner.period }}</div>

            <div>{{ winner.name }}</div>

            <div>{{ winner.phone }}</div>

            <div>{{ winner.prize }}</div>
          </div>
        </div>

        <Pagination :pages="pagination"
                    v-if="data && !noResults"
                    @page-change="pageChange" />
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios'
import Pagination from '@/components/Pagination.vue'

export default {
  name: 'Winners',

  data: () => ({
    data: null,
    winners: [],
    pagination: [],
    searchPhone: '',
    noResults: false,
  }),

  components: {
    Pagination
  },

  mounted(){
    this.getWinners()
  },

  computed: {
  },

  methods: {
    getWinners(pagination){
      if(pagination){
        this.$http.get('/winners?page=' + pagination + '')
            .then((response) => {
              if(!this.pagination.length) {
                for(let i = 0 ; i < response.data.meta.lastPage; i++){
                  this.pagination.push(i + 1)
                }
              }
            this.winners = response.data.data

            if (this.winners.length) {
              this.data = true
            }
        })
      } else {
        this.$http.get('/winners')
            .then((response) => {
              if(!this.pagination.length) {
                for(let i = 0 ; i < response.data.meta.lastPage; i++){
                  this.pagination.push(i + 1)
                }
              }
            this.winners = response.data.data

            if (this.winners.length) {
              this.data = true
            }
        })
      }
    },

    searchWinner(){
      this.$http.get('/winners?search='+ this.searchPhone
          .split('+').join('')
          .split(' ').join('')
          .split('(').join('')
          .split(')').join('')
          .split('+').join('-') + '')
          .then((response) => {
            this.winners = response.data.data
            this.noResults = false

            if (this.winners.length < 1) {
              this.noResults = true
            }
          }).catch(()=>{
        this.winners = []
      })
    },

    pageChange(pageNumber) {
      this.getWinners(pageNumber)
    }
  }
}
</script>

<style scoped lang="scss">
.content-wrapper {

}

.home {
  padding-top: 40px;
  padding-bottom: 70px;
  font-family: $regular;
  min-height: calc(100vh - 126px);

  @media(max-width: 576px){
    padding: 0;
    padding-top: 100px;
    padding-bottom: 30px;
  }

  .section-title {
    text-align: center;
    width: 100%;
    margin-bottom: 50px;
    color: #392A25;
    text-align: center;
    font-family: $regular;
    font-size: 50px;
    font-style: normal;
    font-weight: 900;
    line-height: 50px; /* 100% */

    @media(max-width: 576px){
      margin-bottom: 30px;
    }
  }

  .search-bar {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .button {
      max-width: 115px;
      background-color: #02BA44;
      margin-left: 7px;
      font-size: 16px !important;
    }

    div {
      color: #392A25;
      white-space: nowrap;
      text-align: right;
      font-family: $regular;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      margin-right: 35px;
      line-height: normal;
    }

    @media(max-width: 999px){
      max-width: none;
    }

    @media(max-width: 768px){
      margin-top: 8px;
    }

    @media(max-width: 576px){
      padding: 0 15px;
    }
  }

  .search {
    background-color: $white !important;
    border: none;
    height: 100%;
    transition: .6s all;
    min-height: 32px;
    font-weight: 400;
    padding-left: 20px;
    width: 100%;
    position: relative;
    border-radius: 8px;
    max-width: 260px;
    border: 0.8px solid #02BA44;
    font-family: $regular;

    @media(max-width: 420px){
      font-size: 14px;
    }

    @media(max-width: 350px){
      font-size: 12px;
    }

    &:hover {
      opacity: 0.85;
    }

    &:active,
    &:focus {
      outline: none !important;
      -webkit-box-shadow: 0px 0px 20px 0px rgba(34, 60, 80, 0.1);
      -moz-box-shadow: 0px 0px 20px 0px rgba(34, 60, 80, 0.1);
      box-shadow: 0px 0px 20px 0px rgba(34, 60, 80, 0.1);
    }
  }

  .search-icon {
    position: absolute;
    right: 0;
    top: 13px;
    margin-right: 20px;
  }
}

// table

.table {
  margin-top: 20px;

  @media(max-width: 576px){
    overflow-x: scroll;
  }
}

.table-header {
  width: 100%;
  padding: 0 30px;
  text-align: left;
  background-color: #02BA44;
  color: $white;
  border-radius: 60px;
  height: 57px;
  display: flex;
  align-items: center;
  font-weight: 900;

  @media(max-width: 768px){
    margin: 0;
    padding: 0 15px;
  }

  div {
    width: 25%;
    font-size: 18px;
    font-weight: 700;
    text-align: center;

    @media(max-width: 768px){
      font-size: 12px;
    }

    @media(max-width: 576px){
      height: 100%;
      display: flex;
      align-items: center;

      &:first-child {
        justify-content: flex-end;
        padding-right: 15px;
      }

      &:nth-child(2){
      }

      &:nth-child(3){
        justify-content: flex-end;
        padding-right: 15px;
      }

      &:nth-child(2){
      }
    }
  }
}

.table-line {
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  padding: 15px 30px;
  border-radius: 0px;
  height: 100%;
  height: 64px;
  border-radius: 60px;
  background-color: $white;

  &:nth-child(2n + 1){
    background-color: #E6F9ED;

    @media(max-width: 576px){
      div {
        background-color: #E6F9ED;
      }
    }
  }

  @media(max-width: 768px){
    margin: 0;
    padding: 15px;
  }

  div {
    width: 25%;
    font-size: 18px;
    font-family: $regular;
    text-align: center;

    @media(max-width: 768px){
      font-size: 12px;
    }

    @media(max-width: 576px){
      height: 100%;
      display: flex;
      align-items: center;
      height: 40px;
      text-align: left;

      &:first-child {
        justify-content: flex-end;
        padding-right: 15px;
      }

      &:nth-child(2){
      }

      &:nth-child(3){
        justify-content: flex-end;
        padding-right: 15px;
      }

      &:nth-child(2){
      }
    }
  }
}

// remove later

.plug {
  margin-top: 60px;
  font-size: 32px;
  font-weight: 500;
  width: 100%;
  color: $light-green;
  text-align: center;
  font-family: $regular;
  margin-bottom: 40px;

  @media(max-width: 768px){
    font-size: 16px;
  }
}

.desktop {
  @media(max-width: 768px){
    display: none;
  }
}

.mobile {
  display: none;

  @media(max-width: 768px){
    display: flex;
    margin-left: 17px;
  }
}
</style>
