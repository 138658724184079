import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: localStorage.getItem('token') || '',
    user: null,
    receipts: null,
    errorText: '',
    successText: '',
    email: '',
    prizes: null,
    rules: null,
    policies: null,
    prizeUuid: '',
    personalData: null,
    passport: false,
    delivery: false,
    password: ''
  },
  mutations: {
    saveUser(state, data) {
      state.user = data
    },
    savePrize(state, data) {
      state.prize = data
    },
    saveEmail(state, data) {
      state.email = data
    },
    savePassword(state, data) {
      state.password = data
    },
    saveToken(state, data) {
      state.token = data
    },
    savePrizeUuid(state, data) {
      state.prizeUuid = data
    },
    setReceipts(state, data) {
      state.receipts = data
    },
    setPrizes(state, data) {
      state.prizes = data
    },
    getSuccessText(state, data) {
      state.successText = data
    },
    saveRules(state, data){
      state.rules = data
    },
    savePolicies(state, data){
      state.policies = data
    },
    savePersonalData(state, data){
      state.personalData = data
    },
    getErrorText(state, data) {
      state.errorText = data
    },
    getPrize(state) {
        const prizes = this.state.prizes
        const prizePassport = []
        const prizeDelivery = []

        prizes.forEach(item => {
          if (item.has_passport){
            prizePassport.push(item)
            state.passport = true
            state.prizeUuid = item.id
          }

          if (item.has_delivery){
            prizeDelivery.push(item)
            state.delivery = true
            state.prizeUuid = item.id
          }
        })
    }
  },
  actions: {
    getUser({commit, payload}){
      axios.get('user/account', {
        headers: {
          'Accept': "application/json",
          'Content-Type': "application/json",
          'access-control-allow-origin': "*",
          'Authorization': "Bearer " + localStorage.getItem('token')
        }
      }).then((response) => {
        commit('saveUser', response.data.data)
      }).catch((error) => {
        commit('saveToken', null)
        commit('saveUser', null)

        localStorage.removeItem('token')
      })
    },

    getReceipts({commit}){
      axios.get('receipts', {
        headers: {
          'Accept': "application/json",
          'Content-Type': "application/json",
          'Authorization': "Bearer " + localStorage.getItem('token')
        }
      }).then((response) => {
          commit('setReceipts', response.data.data)
      })
    },

    getPrizes({commit}){
      axios.get('user/prizes', {
        headers: {
          'Accept': "application/json",
          'Content-Type': "application/json",
          'Authorization': "Bearer " + localStorage.getItem('token')
        }
      }).then((response) => {
          commit('setPrizes', response.data.data)
          commit('getPrize')
      })
    },

    getDocuments({commit}, payload) {
      axios.get('documents', {
        headers: {
          'Accept': "application/json",
          'Content-Type': "application/json",
          'access-control-allow-origin': "*"
        }
      }).then((response) => {
        commit('saveRules', response.data.data.filter(data => data.type == 'rules'))
        commit('savePersonalData', response.data.data.filter(data => data.type == 'personal_data'))
        commit('savePolicies', response.data.data.filter(data => data.type == 'privacy_policy'))
      })
    }
  },
  getters : {
    user: state => {
      return state.user
    },
    receipts: state => {
      return state.receipts
    },
    prizes: state => {
      return state.prizes
    },
    token: state => {
      return state.token
    },
    email: state => {
      return state.email
    },
    errorText: state => {
      return state.errorText
    },
    successText: state => {
      return state.successText
    },
    rules: state => {
      return state.rules
    },
    policies: state => {
      return state.policies
    },
    personalData: state => {
      return state.personalData
    },
    password: state => {
      return state.password
    },
    passport: state => {
      return state.passport
    },
    delivery: state => {
      return state.delivery
    },
    prizeUuid: state => {
      return state.prizeUuid
    },
  }
})
