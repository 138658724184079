<template>
  <div class="table-wrapper">
    <div class="dekstop-table">
      <b-row class="table-header">
        <div>Дата загрузки</div>

        <div>Сумма чека</div>

        <div>Статус</div>

        <div>Приз</div>
      </b-row>

      <div class="table">
        <div v-if="!receipts">
          <h3>НЕТ ДАННЫХ</h3>
        </div>

        <b-row class="table-line"
               v-if="receipts"
               v-for="receipt in receipts">
          <div>{{ receipt.created_at }}</div>

          <div>{{ receipt.s }}</div>

          <div class="d-flex align-items-start">
            {{ receipt.description }}
          </div>

          <div>
            <span v-if="(receipt.prizes.length == 0)">―</span>

            {{ receipt.prize }}
          </div>
        </b-row>
      </div>

      <b-row class="table-header">
        <div>Дата загрузки</div>

        <div>Сумма чека</div>

        <div>Статус</div>

        <div>Приз</div>
      </b-row>
    </div>

    <div class="mobile-table">
      <b-row class="table-header">
        <div>Дата загрузки</div>

        <div>Сумма чека</div>

        <div>Статус</div>

        <div>Приз</div>
      </b-row>

      <div class="table">
        <div v-if="!receipts">
          <h3>НЕТ ДАННЫХ</h3>
        </div>

        <b-row class="table-line"
               v-if="receipts"
               v-for="receipt in receipts">
          <div>{{ receipt.created_at }}</div>

          <div>{{ receipt.s }}</div>

          <div class="d-flex align-items-start">
            {{ receipt.description }}
          </div>

          <div>
            <span v-if="(receipt.prizes.length == 0)">―</span>

            {{ receipt.prize }}
          </div>
        </b-row>
      </div>
      
      <b-row class="table-header">
        <div>Дата загрузки</div>

        <div>Сумма чека</div>

        <div>Статус</div>

        <div>Приз</div>
      </b-row>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapState, mapGetters, mapMutations, mapActions } from "vuex"

export default {
  name: 'OfficeTable',

  data: () => ({
  }),

  computed: {
    ...mapGetters({
      receipts: 'receipts',
    }),
  },

  mounted(){
    this.$store.dispatch('getReceipts')
  },

  methods: {
  },
}
</script>

<style scoped lang="scss">
.table-wrapper {
  width: 100%;
  margin-top: 30px;

  @media(max-width: 768px){
    width: 100vw;
    overflow-x: scroll;
  }
}

.table-header {
  width: 100%;
  margin-bottom: 25px;
  padding: 20px 0;
  text-align: left;
  background-color: #3ABBF2;
  color: $white;
  font-family: $regular;

  @media(max-width: 768px){
    margin: 0;
    margin-bottom: 15px;
    flex-wrap: nowrap;
    padding: 0;
  }

  div {
    width: 20%;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    background-color: #3ABBF2;

    &:last-child {
      width: 40%;
      text-align: center;
    }

    @media(max-width: 768px){
      width: 300px;
      font-size: 14px;
      flex-shrink: 0;
      padding: 20px 0;
    }
  }
}

.table-line {
  width: 100%;
  padding: 20px 0;

  &:nth-child(2n){
    background-color: #F6F6F6;
  }

  &:last-child {
    border-bottom: none;
  }

  @media(max-width: 768px){
    margin: 0;
    padding: 15px;
    flex-wrap: nowrap;
  }

  div {
    width: 20%;
    font-size: 18px;
    text-align: center !important;
    justify-content: center;

    &:nth-child(4){
      width: 40%;
    }

    @media(max-width: 768px){
      width: 300px;
      flex-shrink: 0;
      font-size: 14px;
    }
  }
}

.table {
  max-height: 500px;
  overflow-y: scroll;
  padding: 0;
  background-color: $white;
  margin-bottom: 0;

  @media(max-width: 768px){
    padding: 0px;
    max-height: 300px;
    overflow-x: auto;
  }
}

h3 {
  font-weight: 700;
  font-size: 22px;
  margin: 15px;
}

.dekstop-table {
  width: 100%;

  @media(max-width: 768px){
    display: none;
  }
}

.mobile-table {
  display: none;
  width: 100%;

  @media(max-width: 768px){
    display: block;
  }
}

.row {
  margin: 0 !important;
}
</style>
