<template>
  <div>
    <b-modal
      id="reset-modal"
      ref="modal"
      title=""
      hide-footer
      hide-header
      centered
      no-close-on-backdrop
    >
      <div class="header">
        <span class="close-icon"
              @click="closeModal">
          <img src="@/assets/svg/close.svg"
               aria-label="close">
        </span>

        <h3>
          Восстановление <br> пароля
        </h3>

        <div class="subtext">
          Укажите свой адрес электронной почты
        </div>
      </div>

      <div class="w-100 content-modal">
        <form ref="form"
              class="form w-100 modal-form"
              @submit.stop.prevent="submit">
          <b-form-group
            label-for="email-input"
            :invalid-feedback="invalidEmail"
          >
            <b-form-input
              id="email-input"
              placeholder="E-mail"
              v-model="$v.item.email.$model"
              class="form-control"
              :class="{ 'is-invalid' : isInvalidEmail }"
              :state="validateState($v.item.email)"
              required
            ></b-form-input>
         </b-form-group>

         <div class="w-100 d-flex justify-content-center captcha mt-2">
             <vue-recaptcha sitekey="6LeNLR4oAAAAAPGxcVanZQb5iBWpWjCCE8Let1Qg"
                            :loadRecaptchaScript="true"
                            class="captcha"
                            size="100%"
                            ref="recaptcha"
                            @verify="onCaptchaVerified"
                            v-model="$v.item.recaptchaResponse.$model"
                            :state="validateState($v.item.recaptchaResponse)"
             ></vue-recaptcha>
           </div>
        </form>

        <button class="button button-red"
                type="submit"
                :class="{ 'disabled' : disabledBtn }"
                @click="submit">Отправить пароль</button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
import VueRecaptcha from 'vue-recaptcha'
import { validationMixin } from "vuelidate"
import { mapGetters, mapMutations } from "vuex"
import { required, requiredIf, minLength, maxLength, or, helpers } from "vuelidate/lib/validators"

const email = helpers.regex('email', /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)

  export default {
    components: {
      VueRecaptcha
    },

    mixins: [validationMixin],

    validations: {
      item: {
        email: {
          required,
          minLength: minLength(4),
          email
        },
        recaptchaResponse: {
        }
      }
    },

    data() {
      return {
        item: {
          email: '',
          recaptchaResponse: ''
        },
        recaptcha: null,
        isInvalidEmail: false,
        invalidEmail: 'Некорректное поле',
      }
    },

    computed: {
      ...mapGetters({
      }),

      disabledBtn(){
        if ((this.$v.item.$anyError) || (this.item.email.length < 1) || (this.item.recaptchaResponse.length < 1))
          return true
      }
    },

    methods: {
      ...mapMutations({
        getSuccessText: 'getSuccessText',
        saveEmail: 'saveEmail',
        getErrorText: 'getErrorText'
      }),

      closeModal(){
        this.$v.$reset()

        this.item.recaptchaResponse = '',
        this.item.email = '',

        this.$bvModal.hide('reset-modal')
      },

      validateState(item) {
        const { $dirty, $error } = item
        return $dirty ? !$error : null
      },

      onCaptchaVerified(recaptchaToken) {
        this.item.recaptchaResponse = recaptchaToken
      },

      submit() {
        this.$v.item.$touch()

        this.saveEmail(this.item.email)

        if (this.$v.item.$anyError)
          return false

        axios.post('auth/forgot', {
          email: this.item.email,
          "g-recaptcha-response": this.item.recaptchaResponse
        }).then((response) => {
          this.closeModal()

          this.$bvModal.show('password-sent-modal')
        }).catch((error) => {
          this.closeModal()

          this.getErrorText(error.response.data.message)
          this.$bvModal.show('error-modal')
        })
      },
    }
  }
</script>

<style scoped lang="scss">
.header {
  flex-direction: column;
  width: 100%;

  .close-icon {
    float: right;
    cursor: pointer;
    transition: .4s all;
    margin-right: 5px;

    &:hover {
      opacity: .85;
    }
  }
}

.captcha {
  transform: none;
  justify-content: center;
  margin-bottom: 10px;
}

.subtext {
  font-family: $regular;
  color: $white;
  font-size: 15px;
  text-align: center;
  margin-top: -5px;
  margin-bottom: 10px;
}

.error {
  font-size: 8px;
  color: red;
  margin-top: -10px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button {
  width: 100%;
  max-width: 260px;
}

.form {
  max-width: 320px;
}
</style>
