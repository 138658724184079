<template>
  <div>
    <b-modal
         id="password-changed-modal"
         ref="modal"
         title=""
         hide-footer
         hide-header
         centered
     >
      <div class="header">
        <span class="close-icon"
              @click="$bvModal.hide('password-changed-modal')">
          <img src="@/assets/minus.svg"
               aria-label="close">
        </span>

        <h3>
          Пароль успешно изменен.
        </h3>
      </div>
    </b-modal>
  </div>
</template>

<script>

  export default {
    name: 'PasswordChange',

    data() {
      return {
      }
    },

    methods: {
    }
  }
</script>

<style scoped lang="scss">
</style>
